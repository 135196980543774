import React, { useState, useEffect } from "react";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaHeart, FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLikedPosts } from "../../redux/apislice/LikedPostsSlice";
import Skeleton from "react-loading-skeleton";
import { postLike } from "../../redux/apislice/homeSlice";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";

export default function LikedPosts({ onShare }) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [bookmarks, setBookmarks] = useState({});
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    // const [menuPostId, setMenuPostId] = useState(null);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const { posts, loading } = useSelector(state => state.likedPost);

    const handleLike = async (postId) => {
        const response = await dispatch(postLike(postId));
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(getLikedPosts())
        } else {
            console.error('Like action failed');
        }
    };

    const handleBookmark = (postId) => {
        setBookmarks((prevBookmarks) => ({
            ...prevBookmarks,
            [postId]: !prevBookmarks[postId],
        }));
    };

    // VIDEO PLAYER FUNCTIONALITY
    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // MENU FUNCTIONALITY
    // const toggleMenu = (commentId) => {
    //     if (menuPostId === commentId) {
    //         setMenuPostId(null); // Close menu if already open
    //     } else {
    //         setMenuPostId(commentId); // Open menu for this comment
    //     }
    // };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    useEffect(() => {
        dispatch(getLikedPosts())
    }, [dispatch])

    return (
        <div className={`mt-2 mb-4 bg-white transition-transform duration-300 ease-in-out animate-slideInRight`}>
            {loading ? [1, 2, 3].map((index) => (
                <div className="mb-2 border border-gray-300 p-1" key={index}>
                    <div className="grid grid-cols-12">
                        <div className="col-span-2">
                            <Skeleton height={43} width={43} />
                        </div>
                        <div className="col-span-10">
                            <div className="flex flex-col">
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-1">
                                        <Skeleton width={100} />
                                        <Skeleton width={45} />
                                    </div>
                                    <Skeleton width={28} />
                                </div>
                                <Skeleton width={100} />
                                <Skeleton count={1} />
                            </div>
                            <Skeleton height={125} />
                            <div className="flex justify-between items-center">
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                            </div>
                        </div>
                    </div>
                </div>
            )) :
                <div className="px-0">
                    {posts?.Liked_Posts?.map((data) => (
                        <div
                            key={data.id}
                            className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]"
                        >
                            <div className="col-span-2">
                                <img
                                    alt={data?.created_by?.avatar}
                                    src={data?.created_by?.avatar}
                                    className="h-8 w-8 rounded-full object-cover bg-gray-500"
                                />
                            </div>
                            <div className="col-span-10 -ml-2">
                                {/* <div className="flex flex-col">
                                    <div className="flex items-center justify-between relative">
                                        <div className="flex items-center gap-2">
                                            <p className="text-black text-[12px] font-bold">{data?.created_by.first_name} {data?.created_by?.last_name}</p>
                                            <div className="flex items-center">
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                                                <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.postTime}</p>
                                            </div>
                                        </div>
                                        <BsThreeDotsVertical
                                            className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                        />
                                    </div>
                                    <p
                                        className="text-black text-[12px]"
                                        onClick={() => navigate(`/postview/${data.id}`)}
                                    >
                                        {data?.body}
                                    </p>
                                </div> */}
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between relative">
                                        <div className="flex flex-col items-start leading-4 truncate mr-3">
                                            <p className="text-black text-[12px] font-bold">{data?.created_by?.first_name} {data?.created_by?.last_name}</p>
                                            <div className="flex items-center">
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                                                {data?.created_by?.username &&
                                                    <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                }
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">1h ago</p>
                                            </div>
                                        </div>
                                        {/* <BsThreeDotsVertical
                                            className="text-[#5c5c5c] cursor-pointer absolute -right-2 top-[2px]"
                                        /> */}
                                    </div>
                                    <p className="text-black text-[12px] text-start">{data?.body}</p>
                                </div>
                                {data?.image && (
                                    <img
                                        alt={data?.body}
                                        src={data?.image}
                                        onClick={() => navigate(`/postview/${data.id}`)}
                                        className="w-full h-auto object-cover rounded-[6px]"
                                    />
                                )}
                                {data?.video &&
                                    <div className="mt-1 rounded-sm relative">
                                        <ReactPlayer
                                            width="100%"
                                            height="100%"
                                            muted={muted}
                                            url={data?.video}
                                            playing={playing[data.id] || false}
                                            onProgress={(state) => handleProgress(data.id, state)}
                                            onDuration={(duration) => handleDuration(data.id, duration)}
                                        />
                                        <button
                                            onClick={() => handlePlayPause(data.id)}
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                        >
                                            {playing[data.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                        </button>
                                        <button
                                            onClick={handleMute}
                                            className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                        >
                                            {muted ? <GoMute /> : <GoUnmute />}
                                        </button>
                                        <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                            {formatDuration(duration[data.id] - playedSeconds[data.id] || 0)}
                                        </button>
                                    </div>
                                }

                                <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                    <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${data.id}`)}>
                                        <FaRegComment className="text-[14px]" />
                                        <p className="text-[12px]">{data?.comments_count}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1" onClick={() => handleLike(data?.id)}>
                                        <FaHeart className="text-[14px] text-red-500" />
                                        <p className="text-[12px]">{data?.likes_count}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <LuRepeat2 className="text-[16px]" />
                                        <p className="text-[12px]">{data?.postRepost}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FiBarChart2 className="text-[14px]" />
                                        <p className="text-[12px]">{data?.view_count}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        {bookmarks[data?.id] ?
                                            <FaBookmark
                                                className="text-[12px] text-blue-500 cursor-pointer"
                                                onClick={() => handleBookmark(data?.id)}
                                            /> :
                                            <FaRegBookmark
                                                className="text-[12px] cursor-pointer"
                                                onClick={() => handleBookmark(data?.id)}
                                            />}
                                        <AiOutlineShareAlt
                                            className="text-[14px] cursor-pointer"
                                            onClick={() => onShare("https://www.post.share.com")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {posts?.Liked_Posts?.length === 0 && <div className="flex justify-center items-center"><p className="text-[14px] text-gray-500">No Liked videos</p></div>}
                </div>}
        </div>

    )
}
