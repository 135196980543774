
import React from "react"
import EnterOtp from "../components/auth/EnterOtp";

const EnterOtpPage = () => {
   const lable = "C / FE / ENTER OTP PAGE / V2 / SEP 17, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   return (
      <div className="bg-[#d9d9d9] h-screen md:h-[87vh]">
         <div className="text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <EnterOtp />
      </div>
   )
}

export default EnterOtpPage