import React, { Fragment, useEffect, useState } from "react";
import Post from "./Post";
import Like from "./Like";
import Comments from "./Comments";
import Followers from "./Followers";
import Following from "./Following";
import SideMenu from "../SideMenu";
import Reviews from "./Reviews";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { MdLogout } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, followingCount, followersCount } from "../../redux/apislice/profileSlice";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Saved from "./Saved";
import { EncryptFn } from "../../hooks/EncryptFn";
import ShareModal from "../common/ShareModal";

const AccountMain = () => {
   const [selectedTab, setSelectedTab] = useState("Posts");
   const [userDetails, setUserDetails] = useState(null);
   const [logout, setLogout] = useState(false);
   const naviagte = useNavigate();
   const [postCount, setPostCount] = useState(0);
   const [shareModal, setShareModal] = useState(false);
   const [shareUrl, setShareUrl] = useState("");

   const onShare = (url) => {
      setShareModal(true);
      setShareUrl(url)
   }

   const dispatch = useDispatch();
   const profile = useSelector(state => state.profile);

   // Get user pofile
   const postState = useSelector(state => state.post);



   useEffect(() => {
      try {
         dispatch(fetchProfile())?.then((result) => {
            if (result.error) {
               console.log(result.error);
            } else {
               const encryptedUserId = EncryptFn(result.payload.id);
               localStorage.setItem("userId", encryptedUserId);

            }
         });
      } catch (error) {

      }
      dispatch(followingCount());
      dispatch(followersCount());
   }, [dispatch]);

   useEffect(() => {
      setUserDetails(profile.user);
      setPostCount(postState.user?.posts?.length);
   }, [profile, postState]);

   const handleLogout = () => {
      localStorage.removeItem("securityAccessToken");
      localStorage.removeItem("accessToken");
      naviagte("/");
   }
   return (
      <Fragment>
         <div className="sticky top-0 w-full z-50 bg-white">
            <div className="flex justify-between items-center px-2 pt-2 relative">
               <SideMenu />
               {logout === true ?
                  <p className="text-[14px] md:text-[12px] font-bold text-red-600 cursor-pointer" onClick={() => setLogout(false)}>Close</p>
                  :
                  <HiOutlineDotsHorizontal className="text-[22px] cursor-pointer" onClick={() => setLogout(true)} />
               }

               <div className="absolute top-8 right-2">
                  {logout &&
                     <div className="bg-black rounded-md">
                        <p
                           onClick={() => naviagte("/account/edit")}
                           className="text-white px-2 pt-[3px] text-[15px] md:text-[12px] flex items-center justify-start gap-1 cursor-pointer hover:text-red-600"
                        >
                           <FaRegEdit /> Edit Profile
                        </p>
                        <p
                           onClick={handleLogout}
                           className="text-white px-2 py-[2px] text-[15px] md:text-[12px] flex items-center justify-start gap-1 cursor-pointer hover:text-red-600"
                        >
                           <MdLogout /> Logout
                        </p>
                     </div>
                  }
               </div>
            </div>
         </div>
         <div className="sticky top-8 bg-white z-40">
            <div className="flex flex-col justify-center items-center">
               <p className="text-[18px] md:text-[15px] font-bold mb-2 flex gap-[2px]">{userDetails?.first_name || <Skeleton width={50} />} {userDetails?.last_name || <Skeleton width={50} />}</p>
               {profile.loading ?
                  <Skeleton circle width={80} height={80} /> :
                  <img
                     alt="img"
                     className="object-cover h-20 w-20 mb-1 rounded-full"
                     src={userDetails?.avatar_url}
                  />
               }
               <p className="text-[14px] md:text-[13px] font-semibold lowercase">{userDetails?.username || <Skeleton width={100} />}</p>
            </div>

            <div className="grid grid-cols-12 items-center px-3 py-2">
               <div className="col-span-4 text-center">
                  <p className="text-[18px] md:text-[15px] font-bold" onClick={() => setSelectedTab("Posts")}>{postState.loading ? <Skeleton width={20} /> : postCount}</p>
                  <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" onClick={() => setSelectedTab("Posts")}>Posts</p>
               </div>
               <div className="col-span-4 text-center">
                  <p className="text-[18px] md:text-[15px] font-bold" onClick={() => naviagte("/account/followers")}>{profile.followersCount.followers_count === 0 ? 0 : profile.followersCount.followers_count || <Skeleton width={50} />}</p>
                  <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" onClick={() => naviagte("/account/followers")}>Followers</p>
               </div>
               <div className="col-span-4 text-center">
                  <p className="text-[18px] md:text-[15px] font-bold" onClick={() => naviagte("/account/following")}>{profile.followingCount.following_count === 0 ? 0 : profile.followingCount.following_count || <Skeleton width={50} />} </p>
                  <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" onClick={() => naviagte("/account/following")}>Following</p>
               </div>
            </div>

            <div className="flex justify-between bg-[#d9d9d9] text-[14px] md:text-[11px] font-bold px-3 py-2">
               <p className={selectedTab === "Reviews" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => setSelectedTab("Reviews")}>Reviews</p>
               <p className="cursor-pointer" onClick={() => naviagte("/marketplace")}>Marketplace</p>
               <p className={selectedTab === "Saved" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => setSelectedTab("Saved")}>Saved</p>
               <p className={selectedTab === "Comments" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => setSelectedTab("Comments")}>Comments</p>
               <p className={selectedTab === "Posts" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => setSelectedTab("Posts")}>Posts</p>
            </div>
         </div>

         <div className={`${selectedTab === "Saved" ? "" : "px-4"} pt-1`}>
            {selectedTab === "Posts" && <Post />}
            {selectedTab === "Reviews" && <Reviews />}
            {selectedTab === "Like" && <Like />}
            {selectedTab === "Saved" && <Saved onShare={onShare} />}
            {selectedTab === "Comments" && <Comments />}
            {selectedTab === "Followers" && <Followers />}
            {selectedTab === "Following" && <Following />}
         </div>
         {shareModal && <ShareModal url={shareUrl} onClose={() => setShareModal(false)} />}
      </Fragment>
   )
}

export default AccountMain