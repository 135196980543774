import { Card } from "@material-tailwind/react";
import React from "react";
export default function ContectUsPage() {

    return (
        <div className="grid grid-cols-12 overflow-y-scroll bg-gray-200">
            <div className="col-span-12 bg-[#D9D9D9]">
                <div className="text-center bg-[#d9d9d9]">
                    <p className="text-red-700 text-[10px] uppercase font-bold">
                        C CONTACT US PAGE - FE - V2 - SEP 16, 2024
                    </p>
                    <p className="text-black text-[9px]">
                        Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                    </p>
                </div>
            </div>
            <div className="col-span-12 relative w-full">
                <img 
                    alt="Contact Us" 
                    className="mx-auto" 
                    src="https://media.istockphoto.com/id/1450058572/photo/businessman-using-a-laptop-and-touching-on-virtual-screen-contact-icons-consists-of-telephone.jpg?s=2048x2048&w=is&k=20&c=7KhNOYQ1PAxuAcVxu_YieJo5wvIvBpcw1nkXDJ5YM30=" 
                />
            </div>
            <div className="col-span-12 flex justify-center items-center">
                <Card className="w-[90%] mt-6 py-2">
                    <form className="px-2">
                        <div class="bg-white flex flex-col justify-center items-center w-full">
                            <h2 class="text-gray-900 text-[20px] mb-1 font-bold uppercase">Get In Touch!</h2>
                            <p class="leading-4 text-[12px] mb-3 text-gray-600 text-center">
                                We’re here to help! Whether you have a question, feedback, or need support, feel free to reach out. Our team is always ready to assist you
                            </p>
                            <div class="w-full mb-4">
                                <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                                <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div class="w-full mb-4">
                                <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div class="w-full mb-4">
                                <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                                <textarea 
                                    id="message" 
                                    name="message" 
                                    class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" 
                                />
                            </div>
                            <button class="text-white rounded-lg text-center px-6 py-2 mb-4 bg-red-500 border-0 p-4 focus:outline-none hover:bg-red-600">
                                Send
                            </button>
                        </div>
                    </form>
                </Card>
            </div>

            <div className="col-span-12 px-2 mb-28">
                <h1 className="text-[20px] font-bold my-2 text-center uppercase">
                    Office Location
                </h1>
                <p className="leading-4 text-[12px] text-center text-gray-600">
                    Visit us at our headquarters or send us mail at the address below. We’re happy to meet with you by appointment.
                </p>
                <div className="grid grid-cols-12 mt-4">
                    <div className="col-span-4 text-[12px] font-bold uppercase mb-2">
                        Address:
                    </div>
                    <div className="col-span-8 text-gray-800 text-[12px] leading-4 mb-2">
                        123 Your Street, Your City, Your State, ZIP Code
                    </div>
                    <div className="col-span-4 text-[12px] font-bold uppercase mb-2">
                        Office Hours:
                    </div>
                    <div className="col-span-8 text-gray-800 text-[12px] leading-4 mb-2">
                        Monday - Friday, 9:00 AM - 5:00 PM (EST)
                    </div>
                    <div className="col-span-4 text-[12px] font-bold uppercase mb-2">
                        Directions:
                    </div>
                    <div className="col-span-8 text-gray-800 text-[12px] leading-4 mb-2">
                        Get Directions
                    </div>
                </div>
            </div>
        </div>
    );
}
