import React from "react";
import { useNavigate } from "react-router-dom";
import { MdGroups } from "react-icons/md";
import { IoMdStar } from "react-icons/io";

const GroupCreate = () => {
   const lable = "C / FE / GROUP CREATE / V1 / JULY 15, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   const navigate = useNavigate();

   return (
      <form>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>

         <div className="flex items-center justify-between gap-2 px-2 mt-1">
            <p
               onClick={() => navigate(-1)}
               className="text-[12px] text-black hover:text-red-700 font-bold cursor-pointer">
               Cancel
            </p>
            <div className="flex items-center gap-2">
               <p className="text-[14px]">Create Group</p>
               <MdGroups className="text-[24px]" />
            </div>
            <button type="submit" className="text-[12px] text-[#49d7e1] hover:text-[#51edf9] font-bold cursor-pointer bg-transparent">
               Create
            </button>
         </div>

         <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />

         <div className="px-2">
            <div className="mt-2">
               <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                  Tell us a little about your Group. You can always changes these details later.
               </p>
               <div className="mt-1">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group Name</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <input
                     required
                     type="text"
                     placeholder="Enter Group Name"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  />
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Name must be between 3 and 30 chatacters and can't include hashtag or @username
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group Type</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <input
                     required
                     type="text"
                     placeholder="Enter Group Type"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  />
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Group type helps people discover your Group... and it's fun to say!
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group purpose</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <input
                     required
                     type="text"
                     placeholder="Enter a Group purpose"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  />
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     A strong purpose describes the intent of your Group and helps it stand out
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex items-center justify-between">
                     <div className="flex gap-[2px]">
                        <p className="font-bold text-[13px]">Membership type</p>
                        <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                     </div>
                     <select className="focus:outline-none text-[13px]" required>
                        <option>Everyone</option>
                        <option>Restricted</option>
                     </select>
                  </div>
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Control who can join your Group and see your Group's content and members
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Select Group cover picture</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <p className="text-[12px] leading-[14px] text-gray-700 my-1">
                     A cover picture that represents your Group can help people discover it
                  </p>
                  <input
                     required
                     type="file"
                     className="w-full focus:outline-none text-[13px] font-normal"
                  />
               </div>
            </div>
         </div>
      </form>
   )
}

export default GroupCreate