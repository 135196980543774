import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const createPost = createAsyncThunk(
    "createPost",
    async (postData, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/create/`, postData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updatePost = createAsyncThunk(
    "createPost",
    async (postData, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();

            const response = await axios.patch(`${mainUrl}/api/post/update/${postData.postId}/`, postData.formData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const postDelete = createAsyncThunk(
    "postDelete",
    async (post_Id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/post/delete/${post_Id}`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAccountPost = createAsyncThunk(
    "getAccountPost",
    async (userId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/profile/${userId}`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getPostDetails = createAsyncThunk(
    "getPostDetails",
    async (postId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/details/${postId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const postView = createAsyncThunk(
    "postView",
    async (postId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/view/${postId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const postSave = createAsyncThunk(
    "postSave",
    async (postId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/save_post/${postId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeSavedPost = createAsyncThunk(
    "removeSavedPost",
    async (post_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/post/remove_saved_post/${post_id}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const postSlice = createSlice({
    name: "post",
    initialState: {
        loading: false,
        user: null,
        postDetail: {},
        error: null,
    },
    extraReducers: (builder) => {
        // createPost
        builder.addCase(createPost.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(createPost.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(createPost.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });

        // getAccountPost
        builder.addCase(getAccountPost.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(getAccountPost.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(getAccountPost.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
        // getPostDetails
        builder.addCase(getPostDetails.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(getPostDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.postDetail = action.payload;
            state.error = null;
        });
        builder.addCase(getPostDetails.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
    }
});

export default postSlice.reducer;