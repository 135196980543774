import React, { useEffect, useState } from 'react'
import { IoCaretBackCircle, IoFilterCircle } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import PostComment from '../components/post/PostComment';
import { createComment, getComments } from '../redux/apislice/commentSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function CommentPage() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const params = useParams();
   const { id } = params;
   const commentsData = useSelector((state) => state.comments);
   const { loading, data, replyData } = commentsData
   const [showFilter, setShowFilter] = useState(false);
   const [comment, setComment] = useState({
      body: "",
      post_id: id
   });
   const handleShowFilter = () => setShowFilter(!showFilter);

   const handleCommentChange = (e) => {
      setComment({ ...comment, [e.target.name]: e.target.value })
   }

   /* create comment */
   const handleSubmitComment = async () => {
      if (comment.body) {
         const response = await dispatch(createComment({ post_id: id, body: comment.body }));
         if (response && response.meta && response.meta.requestStatus === 'fulfilled') {
            dispatch(getComments(id));
            setComment({ ...comment, body: "" })
         } else {
            console.error('getComment action failed');
         }
      } else {
         console.error('create the comment');
      }
   }

   const handleSubmit = () => {
      handleSubmitComment()
   }

   useEffect(() => {
      dispatch(getComments(id));
   }, [dispatch, id]);

   return (
      <div className='h-full w-full bg-white relative'>
         <div className="text-center pb-[2px] bg-white">
            <p className="text-red-700 text-[9px] uppercase font-bold">C / FE / CommentPage / V1 / July 26, 2024</p>
            <p className="text-black text-[9px]">Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc</p>
         </div>
         <div className='grid grid-cols-12 bg-black px-2 py-2 sticky top-4 z-50'>
            <div className='col-span-2 flex items-center'>
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="text-white cursor-pointer text-[1.3rem]"
               />
            </div>
            <div className='col-span-8 flex  justify-center text-center'>
               <h1 className='text-[16px] font-bold text-white'> <span>{data.length ? `Comments(${data.length})` : "No Comments"}</span></h1>
            </div>
            <div className='col-span-2 flex justify-end items-center'>
               {showFilter ? <p className='text-[12px] text-red-700 cursor-pointer' onClick={handleShowFilter}>close</p> : <IoFilterCircle className='text-white cursor-pointer text-[1.3rem]' onClick={handleShowFilter} />}
            </div>
            <div className="absolute top-8 right-2">
               {showFilter &&
                  <div className="bg-gray-200 rounded-md">
                     <p
                        className="text-black px-2 pt-[3px] text-[15px] md:text-[12px] flex items-center justify-start gap-1 cursor-pointer hover:text-green-600"
                     >
                        Top comments
                     </p>
                     <p
                        className="text-black px-2 py-[2px] text-[15px] md:text-[12px] flex items-center justify-start gap-1 cursor-pointer hover:text-green-600"
                     >
                        Must like
                     </p>
                  </div>
               }
            </div>
         </div>
         <PostComment loading={loading} replyData={replyData} setComment={setComment} commentlist={data} handleSubmit={handleSubmit} handleCommentChange={handleCommentChange} comment={comment} id={id} />
      </div>
   )
}