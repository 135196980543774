import React from "react";
import GroupMain from "../components/group/GroupMain";

const GroupPage = () => {
   return (
      <div className="px-0 pb-20">
         <GroupMain />
      </div>
   )
}

export default GroupPage