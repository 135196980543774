import React, { Fragment, useState } from "react";
import RightSideMenu from "./RightSideMenu";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoCloseCircle } from "react-icons/io5";
import { Input, Select, Option, Textarea } from "@material-tailwind/react";

const Advertise = () => {
    const navigate = useNavigate();
    const [productName, setProductName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [tags, setTags] = useState("");
    const [image, setImage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic, e.g., send data to backend
    };

    const handleSelectImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImage(reader.result);

        }
    }
    const label = "C / FE / ADVERTISE PAGE / V2 / SEP 19, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="sticky top-0 bg-[#FF9900] flex justify-between items-center p-2 z-50">
                <IoCaretBackCircle
                    onClick={() => navigate(-1)}
                    className="text-black cursor-pointer text-[1.2rem]"
                />
                <h1 className="text-[16px] text-center font-bold">Advertise Your Product</h1>
                <RightSideMenu />
            </div>

            <div className="pt-4 px-2 pb-[7rem]">
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <Input
                        required
                        // variant="static"
                        label="Product Name"
                        type="text"
                        value={productName}
                        placeholder="Enter product name"
                        onChange={(e) => setProductName(e.target.value)}
                        className="placeholder:text-[14px]"
                    />

                    <Textarea
                        required
                        value={description}
                        label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <Select label="Select Category">
                        <Option>Electronics</Option>
                        <Option>Fashiont</Option>
                        <Option>Home</Option>
                        <Option>Beauty</Option>
                    </Select>

                    <Input
                        required
                        type="number"
                        // variant="static"
                        label="Price$"
                        value={price}
                        placeholder="Enter product price"
                        onChange={(e) => setPrice(e.target.value)}
                    />

                    <Input
                        required
                        type="number"
                        // variant="static"
                        label="Discount%"
                        value={discount}
                        placeholder="Enter discount percentage"
                        onChange={(e) => setDiscount(e.target.value)}
                    />

                    <Input
                        required
                        type="text"
                        // variant="static"
                        label="Tags"
                        value={tags}
                        placeholder="Enter tags, separated by commas"
                        onChange={(e) => setTags(e.target.value)}
                    />

                    {image &&
                        <div className="w-full relative">
                            <img
                                src={image}
                                alt="product"
                                className="w-full object-cover h-auto rounded"
                            />
                            <IoCloseCircle
                                onClick={() => setImage(null)}
                                className="text-red-900 absolute -top-4 right-0 text-[16px] cursor-pointer"
                            />
                        </div>
                    }

                    {!image &&
                        <Input
                            required
                            // variant="static"
                            label="Add Image"
                            placeholder="Add product image"
                            type="file"
                            onChange={(e) => handleSelectImage(e)}
                        />
                    }

                    <div className="flex justify-center items-center">
                        <button
                            type="submit"
                            className="px-3 py-2 text-[12px] bg-black text-white font-bold rounded-full hover:bg-black-600"
                        >
                            Submit Advertise
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    )
};

export default Advertise;

