import React, { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ForYou({ posts, loading }) {

    // VIDEO PLAYER FUNCTIONALITY
    const [durations, setDurations] = useState({});
    const videoRefs = useRef([]);

    useEffect(() => {
        const currentVideoRefs = videoRefs.current;

        const handleLoadedMetadata = (index) => {
            const video = currentVideoRefs[index];
            if (video) {
                const minutes = Math.floor(video.duration / 60);
                const seconds = Math.floor(video.duration % 60);
                const formattedTime =
                    (minutes < 10 ? '0' : '') + minutes + ':' +
                    (seconds < 10 ? '0' : '') + seconds;
                setDurations(prevDurations => ({
                    ...prevDurations,
                    [index]: formattedTime
                }));
            }
        };

        currentVideoRefs.forEach((video, index) => {
            if (video) {
                video.addEventListener('loadedmetadata', () => handleLoadedMetadata(index));
            }
        });

        return () => {
            currentVideoRefs.forEach((video, index) => {
                if (video) {
                    video.removeEventListener('loadedmetadata', () => handleLoadedMetadata(index));
                }
            });
        };
    }, [posts]);


    const filteredData = posts?.filter(post => post.image !== null || post.video !== null);

    console.log(filteredData?.length, "posts?.length")

    return (
        <div className='grid grid-cols-12 gap-[1px] mt-2'>
            {loading ?
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => (
                    <div className="col-span-4" key={index}>
                        <Skeleton height={90} width={100} />
                    </div>
                )) :
                filteredData?.map((post, index) => (
                    <div key={index} className='col-span-4 h-24 w-full'>
                        {post.image && <img src={post.image} alt={post.body} className='w-full h-24 object-cover' />}
                        {post.video &&
                            <div className="rounded-sm relative h-24">
                                <video
                                    ref={el => videoRefs.current[index] = el}
                                    src={post.video}
                                    className='h-full w-full object-cover'
                                />
                                <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                    {durations[index] || '00:00'}
                                </button>
                            </div>
                        }
                    </div>
                ))}

            {!filteredData?.length  && <p className='col-span-12 my-24 flex justify-center items-center  text-center text-gray-600'>No posts found</p>}
        </div>
    );
}