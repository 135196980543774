import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewPassword } from "../../redux/apislice/authSlice";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IoEye } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { DecryptFn } from "../../hooks/DecryptFn";
const NewPass = () => {
   const navigate = useNavigate();
   const auth = useSelector((state) => state.auth);
   const [showPass, setShowPass] = useState({ password: false, confirmPassword: false });
   const dispatch = useDispatch();

   const [newPass, setNewPass] = useState({
      password1: "",
      password2: "",
      email: ""
   });
   const [errors, setErrors] = useState({
      password1: "",
      password2: "",
      match: ""
   });

   const validatePassword = (password) => {
      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return strongPasswordRegex.test(password);
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setNewPass({ ...newPass, [name]: value });

      // Validate passwords
      if (name === "password1" || name === "password2") {
         if (!validatePassword(value)) {
            setErrors((prevErrors) => ({
               ...prevErrors,
               [name]: "Password must be 8+ characters with at least one uppercase, one lowercase, a number, and a special character."
            }));
         } else {
            setErrors((prevErrors) => ({
               ...prevErrors,
               [name]: ""
            }));
         }
      }

      //   // Check if passwords match
      if (name === "password1" || name === "password2") {

         if (newPass.password1 !== newPass.password2) {
            setErrors((prevErrors) => ({
               ...prevErrors,
               match: "Passwords do not match."
            }));
         } else {
            setErrors((prevErrors) => ({
               ...prevErrors,
               match: ""
            }));
         }
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (newPass.password1 !== newPass.password2 || newPass.password1 === "" || newPass.password2 === "") {
         toast.error("Passwords do not match.");
         return;
      }
      if (!(errors.password1 || errors.password2 || errors.match)) {
         toast.error("Please fix the errors before submitting.");
         return;
      }

      dispatch(setNewPassword(newPass)).then((result) => {
         if (result.error) {
            toast.error("Failed to update password.");
            console.error(result.error);
         } else {
            toast.success("Password updated successfully!");
            localStorage.removeItem("email");
            if (auth.loading === false) {
               setTimeout(() => {
                  navigate("/login");
               }, 3000);
            }
         }
      }).catch((error) => {
         toast.error("An error occurred.");
         console.error(error);
      });
   };

   const handleShowPassword = () => {
      setShowPass((prev) => ({ ...prev, password: !showPass.password }))
   }
   const handleShowConfirmPassword = () => {
      setShowPass((prev) => ({ ...prev, confirmPassword: !showPass.confirmPassword }))
   }
   useEffect(() => {
      const decryptedEmail = DecryptFn(localStorage.getItem("email"));
      setNewPass((prev) => ({ ...prev, email: decryptedEmail }))
   }, [])

   return (
      <div className="flex flex-col justify-center items-center px-4 mt-[4rem]">
         <Toaster position="top-right" />
         <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px] mb-3">Creative</p>
         <div className="h-[43vh] w-full rounded-[20px] p-4 bg-white overflow-hidden">
            <p className="font-bold text-[24px] md:text-[20px] text-center">New Password</p>
            <p className="text-[16px] md:text-[13px] text-center">Create new password</p>
            <form className="mt-4" onSubmit={handleSubmit}>
               <div className="mb-4">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">abc@example.com</p>
                  <div className="relative">

                     <input

                        type={showPass.password ? "text" : "password"}
                        placeholder="New password"
                        name="password1"
                        required
                        className="w-full focus:outline-none border-gray-700 px-[8px] py-[8px] md:py-[4px] border-[1px] rounded-md mb-3"
                        value={newPass.password1}
                        onChange={handleChange}
                     />
                     <p className="absolute top-2 right-2 z-50">
                        {showPass.password ? <IoEye onClick={() => handleShowPassword()} /> : <FaEyeSlash onClick={() => handleShowPassword()} />}
                     </p>
                  </div>
                  <div className="relative">

                     <input
                        type={showPass.confirmPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        name="password2"
                        required
                        className="w-full focus:outline-none border-gray-700 px-[8px] py-[8px] md:py-[4px] border-[1px] rounded-md"
                        value={newPass.password2}
                        onChange={handleChange}
                     />
                     <p className="absolute top-2 right-2 z-50">
                        {showPass.confirmPassword ? <IoEye onClick={() => handleShowConfirmPassword()} /> : <FaEyeSlash onClick={() => handleShowConfirmPassword()} />}
                     </p>
                  </div>
               </div>
               <div className="mb-4">
                  {(errors.password1 || errors.password2) && <p className="text-red-500 text-xs">Password must be 8+ characters with uppercase, lowercase, number, and special character.</p>}
               </div>
               <div className="flex items-center gap-1">
                  <input type="checkbox" className="rounded-full accent-green-700 h-3 w-4 cursor-pointer" />
                  <p className="text-[15px] md:text-[13px]">Logout from all devices</p>
               </div>
               <button
                  type="submit"
                  className="w-full bg-[#7000FF] text-white rounded-md py-[10px] md:py-[5px] mt-4"
               >
                  Login with new password
               </button>
            </form>
         </div>
      </div>
   );
};

export default NewPass;