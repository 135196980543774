import React from "react";
import Popular from "../components/marketplace/Popular";

const MarketPlacePage = () => {
   return (
      <div className="px-0 mb-24">
         <Popular />
      </div>
   )
}

export default MarketPlacePage