import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { IoCaretBackCircle } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { getAllHashTags } from '../../redux/apislice/hashtagSlice';
import Skeleton from 'react-loading-skeleton';

const HashtagSearch = () => {
    const lable = "C / FE / HASHTAG SEARCH / V1 / AUG 22, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hashtag = useSelector(state => state.hashTag)
    const { loading, allData } = hashtag;
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredHashtags = allData?.filter(tag =>
        tag.hashtag.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        dispatch(getAllHashTags())
        return () => {
            setSearchQuery("")
        }
    }, [dispatch])
    return (
        <div className="mb-28">
            <div className="sticky top-0 bg-white z-50">
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[9px]">{copyright}</p>
                </div>
                <div className="flex items-center gap-2 px-2 mt-1">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer"
                    />
                    <input
                        type="text"
                        onChange={handleSearchChange}
                        placeholder="Search for Hash Tags"
                        className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full text-white focus:outline-none focus:bg-[#dedede] focus:text-black bg-black"
                    />
                </div>
                <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />
            </div>

            <div className="mt-4 mb-5">
                {loading ?
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                        <div className="w-full px-2" key={index}>
                            <Skeleton height={30} width={310} />
                        </div>
                    )) :
                    filteredHashtags.map((data) => (
                        <div key={data.id} className="flex gap-2 mb-2 px-2 py-1">
                            <div className="flex flex-col">
                                <div className="flex items-center">
                                    <p className="text-[12px] font-bold cursor-pointer text-black" onClick={() => navigate(`/hashtag/${data.hashtag}`)}>#{data.hashtag}</p>
                                    <LuDot className="h-3 w-3" />
                                    <p className="text-[12px] text-gray-500">1.6M posts</p>
                                </div>
                            </div>
                        </div>
                    ))}
                {filteredHashtags.length === 0 && <p className="text-center text-gray-500">No hashtags found</p>}
            </div>
        </div>
    )
}

export default HashtagSearch
