import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const fetchProfile = createAsyncThunk(
    "fetchProfile",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/profile/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const editProfileDetails = createAsyncThunk(
    "editProfileDetails",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.patch(`${mainUrl}/api/profile/update/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const fetchFollowersList = createAsyncThunk(
    "fetchFollowersList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/followers_list`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const fetchFollowingList = createAsyncThunk(
    "fetchFollowingList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/following_list/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const startFollow = createAsyncThunk(
    "startFollow",
    async (user_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/follow/${user_id}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const followingCount = createAsyncThunk(
    "followingCount",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/following_count/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const followersCount = createAsyncThunk(
    "followersCount",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/followers_count/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        loading: false,
        user: {},
        followers: [],
        following: [],
        followingCount: {},
        followersCount: {},
        error: null,
    },
    extraReducers: (builder) => {
        // Fetch Profile
        builder.addCase(fetchProfile.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(fetchProfile.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Edit Name
        builder.addCase(editProfileDetails.pending, (state) => {
            state.loading = true;

            state.error = null;
        });
        builder.addCase(editProfileDetails.fulfilled, (state, action) => {
            state.loading = false;
            // state.user = action.payload;
            state.error = null;
        });
        builder.addCase(editProfileDetails.rejected, (state) => {
            state.loading = false;

            state.error = true;
        });

        // Fetch followers list
        builder.addCase(fetchFollowersList.pending, (state) => {
            state.loading = true;

            state.error = null;
        });
        builder.addCase(fetchFollowersList.fulfilled, (state, action) => {
            state.loading = false;
            state.followers = action.payload;
            state.user = { ...state.user }
            state.error = null;
        });
        builder.addCase(fetchFollowersList.rejected, (state) => {
            state.loading = false;

            state.error = true;
        });
        // Fetch following list
        builder.addCase(fetchFollowingList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchFollowingList.fulfilled, (state, action) => {
            state.loading = false;
            state.following = action.payload;
            state.user = { ...state.user }
            state.error = null;
        });
        builder.addCase(fetchFollowingList.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // start follow list
        builder.addCase(startFollow.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(startFollow.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(startFollow.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // start followingCount
        builder.addCase(followingCount.pending, (state) => {
            state.loading = true;
            state.user = { ...state?.user };
            state.error = null;
        });
        builder.addCase(followingCount.fulfilled, (state, action) => {
            state.loading = false;
            state.followingCount = action.payload;
            state.error = null;
        });
        builder.addCase(followingCount.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // start followersCount
        builder.addCase(followersCount.pending, (state) => {
            state.loading = true;
            state.user = { ...state?.user };
            state.error = null;
        });
        builder.addCase(followersCount.fulfilled, (state, action) => {
            state.loading = false;
            state.followersCount = action.payload;
            state.error = null;
        });
        builder.addCase(followersCount.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default profileSlice.reducer;