import React from "react";
import { post } from "../../data";
import { IoEye } from "react-icons/io5";
import { BiRepost } from "react-icons/bi";
import { IoIosBookmarks } from "react-icons/io";
import { FaCommentDots, FaCheck } from "react-icons/fa";

const Like = () => {
   return (
      <div>
         {post.map((data) => (
            <div className="mb-6" key={data.id}>
               <img
                  alt={data.firstName}
                  src={data.postImage}
                  className="mt-1 object-cover"
               />
               <div className="flex justify-between mt-1">
                  <div className="flex flex-col items-center justify-center">
                     <FaCommentDots className="text-[22px] cursor-pointer" />
                     <p className="text-[10px] font-bold mt-[-1px]">{data.comments}</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                     <FaCheck className="text-[22px] cursor-pointer text-[#24FF00]" />
                     <p className="text-[10px] font-bold mt-[-1px]">{data.likes}</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                     <BiRepost className="text-[25px] cursor-pointer" />
                     <p className="text-[10px] font-bold mt-[-3px]">{data.repost}</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                     <IoEye className="text-[22px] cursor-pointer" />
                     <p className="text-[10px] font-bold mt-[-1px]">{data.viewed}</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                     <IoIosBookmarks className="text-[22px] cursor-pointer" />
                     <p className="text-[10px] font-bold mt-[-1px]">{data.bookmarked}</p>
                  </div>
               </div>
            </div>
         ))}
      </div>
   )
}

export default Like