import React, { Fragment, useEffect, useState, useRef } from "react"
import { FaBookmark, FaCommentDots, FaRegBookmark } from "react-icons/fa6"
import { FiEye } from "react-icons/fi"
import { IoIosShareAlt } from "react-icons/io"
import { IoCaretBackCircle, IoClose, IoSend } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import ShareModal from "../common/ShareModal"
import { post } from "../../data"
import { HiDotsHorizontal } from "react-icons/hi";

export default function NewsRead() {
    const navigate = useNavigate()
    const [bookmarks, setBookmarks] = useState(false);
    const lable = "C / FE / NEWS READ/ PAGE / V1 / SEP 16, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
    const [share, setShare] = useState(false)
    const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);
    const bottomDrawerRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [menuPostId, setMenuPostId] = useState(null);
    const menuRef = useRef(null);

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
    };

    const handleClickOutside = (event) => {
        if (bottomDrawerRef.current && !bottomDrawerRef.current.contains(event.target)) {
            setIsBottomDrawerOpen(false);
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuPostId(null); // Close menu if clicked outside
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    const toggleBottomDrawer = () => {
        setIsBottomDrawerOpen(!isBottomDrawerOpen);
    };

    const newsDetails = {
        id: 1,
        img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
        headline: "Breaking News: Market Reaches New Highs",
    }

    const newsDetails1 = [
        "Global financial markets have surged to record levels, buoyed by a combination of strong corporate earnings, investor optimism, and favorable economic indicators. The S&P 500 and NASDAQ have both hit all-time highs, reflecting a broad-based rally across sectors, with tech and consumer discretionary stocks leading the charge.",

        "This historic rise comes amid growing confidence in economic recovery, driven by robust consumer spending, low unemployment, and sustained growth in key industries. Central banks' accommodative monetary policies have also played a crucial role in supporting asset prices, with investors continuing to pour capital into equities.",

        "However, some analysts caution that the rapid pace of gains may be unsustainable, pointing to potential risks such as inflationary pressures, geopolitical tensions, and the possibility of tighter monetary policies in the future. Despite these concerns, market sentiment remains overwhelmingly positive, as investors look forward to continued growth in the coming quarters.",

        "The market's unprecedented performance underscores the resilience of the global economy, even in the face of ongoing challenges, and highlights the importance of staying informed about the latest developments in the financial world. As the recovery gathers momentum, investors are advised to remain vigilant and adapt their strategies to navigate the evolving landscape effectively."
    ]

    // console.log(isBottomDrawerOpen, "isBottomDrawerOpen")

    return (
        <Fragment>
            <div className="relative">
                <div className="text-center bg-[#d9d9d9]">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[9px]">{copyright}</p>
                </div>
                <div className="grid grid-cols-12 bg-white mb-24 overflow-x-hidden">
                    <div className="col-span-12 relative">
                        <div className="absolute top-0 h-full w-full z-30">
                            <div className="h-full w-full relative">
                                <div className="flex items-center justify-between bg-[#0000002f] px-2 py-1">
                                    <div className="col-span-1 flex justify-start items-center">
                                        <IoCaretBackCircle 
                                            onClick={() => navigate(-1)} 
                                            className="text-[18px] text-white cursor-pointer" 
                                        />
                                    </div>
                                    <div className="flex justify-center items-center gap-x-6">
                                        <IoIosShareAlt className="text-[20px] text-white cursor-pointer" onClick={() => setShare(true)} />
                                        {bookmarks ?
                                            <FaBookmark
                                                className="text-[16px] text-blue-500 cursor-pointer"
                                                onClick={() => setBookmarks(!bookmarks)}
                                            /> :
                                            <FaRegBookmark
                                                className="text-[16px] text-white cursor-pointer"
                                                onClick={() => setBookmarks(!bookmarks)}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="absolute bg-[#00000076] bottom-0 left-0 w-full z-30 flex justify-between items-center py-1 px-2">
                                    <p className="text-white font-bold text-[12px]">2 hours ago</p>
                                    <div className="flex items-center gap-x-4">
                                        <div className="flex items-center gap-x-1">
                                            <FaCommentDots 
                                                onClick={toggleBottomDrawer} 
                                                className="text-[1rem] text-white cursor-pointer" 
                                            />
                                            <p className="text-white text-[12px]">12</p>
                                        </div>
                                        <div className="flex items-center gap-x-1">
                                            <FiEye className="text-[1rem] text-white cursor-pointer" />
                                            <p className="text-white text-[12px]">120</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img 
                            alt="newsDetails" 
                            src={newsDetails.img} 
                            className="h-48 w-full object-cover" 
                        />
                    </div>
                    <div className="px-2 col-span-12 my-2">
                        <p className="text-black border-l-4 text-[18px] font-bold px-2 border-orange-500">
                            {newsDetails.headline}
                        </p>
                    </div>
                    <div className="col-span-12 px-2">
                        {newsDetails1.map((data, index) => (
                            <p className="text-[12px] text-justify mb-3 font-medium" key={index}>{data}</p>
                        ))}
                    </div>
                </div>
                {isBottomDrawerOpen && (
                    <div
                        ref={bottomDrawerRef}
                        id="drawer-bottom-example"
                        className="sticky bottom-28 md:bottom-24 left-0 rounded-t-3xl right-0 z-50 w-full h-[28rem] p-4 overflow-y-auto transition-transform bg-black dark:bg-gray-800 transform-none"
                        tabIndex="-1"
                        aria-labelledby="drawer-bottom-label"
                    >
                        <h5
                            id="drawer-bottom-label"
                            className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
                        >
                            News Comments
                        </h5>
                        <button
                            type="button"
                            onClick={toggleBottomDrawer}
                            aria-controls="drawer-bottom-example"
                            className=" bg-transparent hover:bg-red-500 text-red-600 hover:text-black rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <IoClose className="font-bold text-[1.5rem]" />
                        </button>

                        {post.map((data) => (
                            <div className="grid grid-cols-12 mb-2">
                                <div className="col-span-2">
                                    <img src={data.profileImage} alt="img" className="rounded-md h-8 w-8 object-cover cursor-pointer" />
                                </div>
                                <div className="col-span-8 flex justify-between flex-col">
                                    <div className="flex flex-col justify-between">
                                        <p className="text-[12px] text-gray-300 font-bold cursor-pointer">{data.firstName} {data.lastName}</p>
                                        <p className="text-[10px] underline text-gray-400 font-bold cursor-pointer">{data.userName}</p>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-[12px] text-gray-500 font-medium">{data.bio}</p>
                                    </div>
                                </div>
                                <div className="col-span-2 flex flex-col justify-between items-end relative">
                                    <HiDotsHorizontal className="text-gray-400" onClick={() => setMenuPostId(data.id)} />
                                    <p className="text-[8px] font-semibold text-blue-300">{data.postTimeAgo}</p>
                                    {menuPostId === data?.id && (
                                        <div ref={menuRef} className="absolute right-0 mt-4 z-50">
                                            <div className="bg-[#FFFFFF] flex flex-col rounded-[5px]">
                                                <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" >
                                                    <p className="text-[10px] font-bold">Report</p>
                                                </div>
                                                <hr className="h-[0.8px] bg-gray-500 border-0" />
                                                <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" >
                                                    <p className="text-[10px] font-bold">Block</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className="sticky -bottom-4 z-30 w-full bg-black py-2">
                            <div className="">
                                <div className="flex items-center justify-between gap-1">
                                    <input
                                        type="text"
                                        placeholder="Comment here"
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        className="bg-white rounded-full focus:outline-none w-full px-3 py-1 text-gray-800"
                                    />
                                    {isInputFocused && (
                                        <div className="bg-white p-2 rounded-full cursor-pointer">
                                            <IoSend className="text-black" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </div>
            {share && <ShareModal url={"https/news/share"} onClose={() => setShare(false)} />}
        </Fragment>
    )
}




