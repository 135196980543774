import React from "react";

const YesNoModal = ({ heading, onClose, type }) => {
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50" onClick={onClose}>
            <div className="bg-white px-1 py-4 rounded-lg shadow-lg w-11/12 max-w-lg" onClick={(e) => e.stopPropagation()}>
                <p className="text-center text-[14px] uppercase font-bold">
                    {heading}
                </p>

                {type === "report" && (
                    <div className="mt-4 px-2">
                        <label className="flex items-center mb-2">
                            <input type="checkbox" className="mr-2" />
                            Inappropriate Content
                        </label>
                        <label className="flex items-center mb-2">
                            <input type="checkbox" className="mr-2" />
                            Spam or Advertising
                        </label>
                        <label className="flex items-center mb-2">
                            <input type="checkbox" className="mr-2" />
                            Harassment or Hate Speech
                        </label>
                        <label className="flex items-center mb-2">
                            <input type="checkbox" className="mr-2" />
                            Misinformation
                        </label>
                    </div>
                )}

                <div className="flex items-center justify-around mt-5">
                    <button
                        onClick={onClose}
                        className="uppercase text-[12px] px-4 py-2 rounded-md bg-black hover:bg-[#101010] text-white"
                    >
                        YES
                    </button>
                    <button
                        onClick={onClose}
                        className="uppercase text-[12px] px-4 py-2 rounded-md bg-black hover:bg-[#101010] text-white"
                    >
                        NO
                    </button>
                </div>
            </div>
        </div>
    );
};

export default YesNoModal;
