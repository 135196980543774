import React, { Fragment } from "react";
import MainRouter from "./router/MainRouter";

const App = () => {
   return (
      <Fragment>
         <MainRouter />
      </Fragment>
   )
}

export default App