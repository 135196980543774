import React, { useState } from "react";
import ReactPlayer from "react-player";

export default function ReportModal({ setReportPost }) {
    const [activeStep, setActiveStep] = useState(0);
    const [checkPersonalization, setCheckPersonalization] = useState(
        [
            { id: "1", name: "Spam", isChecked: false },
            { id: "2", name: "Harassment or Bullying", isChecked: false },
            { id: "3", name: "Hate Speech or Offensive Language", isChecked: false },
            { id: "4", name: "Misinformation", isChecked: false },
            { id: "5", name: "Nudity or Sexual Content", isChecked: false },
            { id: "6", name: "Violence or Threats", isChecked: false },
            { id: "7", name: "Intellectual Property Violation", isChecked: false },
            { id: "8", name: "Other", isChecked: false }
        ]
    );

    const handleCheck = (e) => {
        const { id, checked } = e.target;
        setCheckPersonalization((prevState) => prevState.map((item) => {
            if (item.id === id) {
                return { ...item, isChecked: checked };
            }
            else {
                return { ...item, isChecked: false };
            }
        }));
    }

    const handleNext = () => setActiveStep((cur) => cur + 1);
    const handlePrev = () => setActiveStep((cur) => cur - 1);

    const lable = `C / FE /REPORT PAGE TAB-${activeStep} / V2 / SEP 20, 2024`;
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-start pt-4 justify-center z-50">
            <div className="bg-white px-2 py-1 rounded-lg shadow-lg w-11/12 max-w-lg relative" >
                {activeStep !== 3 &&
                    <div div className="flex justify-center items-center">
                        <p className="text-[18px] text-[red] font-bold py-1">Report Content</p>
                    </div>
                }
                <form>
                    {activeStep === 0 && (
                        <div className="flex justify-center items-center w-full">
                            <div className="grid grid-cols-12">
                                <p className="col-span-12 text-[10px] pb-1 text-center">
                                    Provide a list of common reasons for reporting content.
                                </p>
                                <div className="col-span-12 mt-3">
                                    {checkPersonalization.map((data) => (
                                        <div className={`flex justify-between items-center mb-2 p-1 w-full border border-gray-300 rounded-md text-gray-700 hover:bg-black hover:text-white outline-none transition-all duration-500 ease-in-out cursor-pointer ${data.isChecked ? "bg-black text-white outline-none mb-1 items-center" : ""}`} key={data.id}>
                                            <label className="ml-2 text-[12px] font-medium">{data.name}</label>
                                            <input
                                                id={data.id}
                                                type="checkbox"
                                                onChange={handleCheck}
                                                checked={data.isChecked}
                                                className="rounded-full w-3 h-3 accent-green-700 cursor-pointer"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 1 && (
                        <div className="px-2 rounded  transform">
                            <div className="grid grid-cols-6">
                                <p className="col-span-6 text-[10px] text-center leading-3">
                                    Please let us know why you're reporting this post. We take reports
                                    seriously and will review the content as soon as possible.
                                </p>
                                <textarea
                                    rows={5}
                                    type="text"
                                    placeholder="Write your reason here ..."
                                    className="col-span-6 my-2 w-full border border-gray-300 rounded-md p-2 resize-none outline-none placeholder:text-[12px]"
                                />
                            </div>
                        </div>
                    )}

                    {activeStep === 2 && (
                        <div className="w-[100%] left-1/2 transform ">
                            <div className="grid grid-cols-12">
                                <div className="col-span-2">
                                    <img
                                        src={"https://images.unsplash.com/photo-1722111736583-83d0da21c090?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDEyfHRvd0paRnNrcEdnfHxlbnwwfHx8fHw%3D"}
                                        alt={""}

                                        className="h-10 w-10 bg-black object-cover cursor-pointer rounded-full"
                                    />
                                </div>
                                <div className="col-span-10">
                                    <div className="flex justify-between relative">
                                        <div className="mt-[-2px]">
                                            <p className="text-[13px] font-bold">

                                                James Smith
                                            </p>
                                            <p className="text-[10px] font-bold tracking-[0.5px] mt-[-4px]">@James</p>
                                        </div>
                                    </div>
                                    {true &&
                                        <img
                                            alt="post"
                                            src={"https://plus.unsplash.com/premium_photo-1722077701586-a81474ac7289?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyfHx8ZW58MHx8fHx8"}
                                            className="mt-1 object-cover rounded-sm"
                                        />
                                    }
                                    {false &&
                                        <div className="mt-1 rounded-sm relative" >
                                            <ReactPlayer
                                                url={""}
                                                playing={false}
                                                muted={true}
                                                width="100%"
                                                height="100%"
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                    {activeStep === 3 && (
                        <div className="w-[100%] left-1/2 transform ">
                            <div className="grid grid-cols-12">
                                <p className="col-span-12 text-center text-[14px] font-bold">Thank you for your report. Our team will review the content and take appropriate action</p>
                            </div>
                        </div>
                    )}
                </form>

                {activeStep !== 3 &&
                    <div className="w-full flex justify-between px-5 h-[3rem] items-center">
                        {activeStep < 2 && <button
                            className="rounded-full bg-[#151515] hover:bg-[#272727] text-[12px] font-bold px-10 h-7 text-[#fff]"
                            onClick={handleNext}
                        >
                            Next
                        </button>}
                        {activeStep === 2 && <button
                            className="rounded-full bg-[#ff3232] hover:bg-[#ea7777] text-[12px] font-bold px-10 h-7 text-[#fff]"
                            onClick={handleNext}
                        > Report
                        </button>}
                        {activeStep !== 3 && <button
                            className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                            onClick={() => activeStep === 0 ? setReportPost(false) : handlePrev()}
                        >
                            Cancel
                        </button>}
                    </div>
                }

                {activeStep === 3 &&
                    <div className="w-full flex justify-center items-center p-4">
                        <button
                            className="rounded-full text-[12px] px-10 h-7 text-white bg-black font-bold  hover:bg-[#656363] "
                            onClick={() => setReportPost(false)}
                        >
                            Go back
                        </button>
                    </div>
                }

                <div className="text-center">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[9px]">{copyright}</p>
                </div>
            </div>
        </div >
    )
}

