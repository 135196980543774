const getAccessConfig = () => {
   const accessToken = localStorage.getItem("accessToken");

   if (!accessToken) {
      throw new Error("No access token found");
   }

   return {
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   };
};

export default getAccessConfig;