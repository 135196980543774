
import React from "react";
import { post } from "../../data";
import Carousel from "react-multi-carousel";
import { MdRemoveRedEye } from "react-icons/md";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function HealthSearch() {
    const navigate = useNavigate();
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const sortedPosts = post.sort((a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        return 0;
    });
    console.log(sortedPosts);


    const uniquePosts = [
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8"
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%"
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8"
        },
    ]; 

    const trending_foods =
        [
            {
                hashtag: "#Foodie",
                post_count: 95000000,
                trend_direction: "up",
                growth_rate_percentage: 5.2,
                regions_popular: ["United States", "Canada", "United Kingdom"],
                related_hashtags: ["#Yummy", "#Delicious", "#FoodPorn"]
            },
            {
                hashtag: "#Vegan",
                post_count: 48000000,
                trend_direction: "up",
                growth_rate_percentage: 7.8,
                regions_popular: ["Germany", "Australia", "United States"],
                related_hashtags: ["#PlantBased", "#VeganRecipes", "#HealthyEating"]
            },
            {
                hashtag: "#Keto",
                post_count: 32000000,
                trend_direction: "down",
                growth_rate_percentage: -2.4,
                regions_popular: ["United States", "Canada", "Brazil"],
                related_hashtags: ["#KetoDiet", "#LowCarb", "#Ketogenic"]
            },
            {
                hashtag: "#GlutenFree",
                post_count: 22000000,
                trend_direction: "up",
                growth_rate_percentage: 3.6,
                regions_popular: ["United Kingdom", "Australia", "United States"],
                related_hashtags: ["#Celiac", "#GF", "#HealthyLifestyle"]
            },
            {
                hashtag: "#FoodPhotography",
                post_count: 78000000,
                trend_direction: "up",
                growth_rate_percentage: 4.1,
                regions_popular: ["Italy", "France", "Japan"],
                related_hashtags: ["#FoodStyling", "#InstaFood", "#Foodstagram"]
            },
            {
                hashtag: "#Homemade",
                post_count: 67000000,
                trend_direction: "up",
                growth_rate_percentage: 6.5,
                regions_popular: ["India", "United States", "Mexico"],
                related_hashtags: ["#HomeCooking", "#MadeFromScratch", "#Cooking"]
            },
            {
                hashtag: "#CheatMeal",
                post_count: 15000000,
                trend_direction: "down",
                growth_rate_percentage: -1.8,
                regions_popular: ["United States", "Canada", "Australia"],
                related_hashtags: ["#TreatYourself", "#Foodgasm", "#Indulge"]
            },
            {
                hashtag: "#MealPrep",
                post_count: 42000000,
                trend_direction: "up",
                growth_rate_percentage: 5.9,
                regions_popular: ["United States", "Canada", "United Kingdom"],
                related_hashtags: ["#HealthyMeals", "#FitnessFood", "#PrepAhead"]
            },
            {
                hashtag: "#StreetFood",
                post_count: 31000000,
                trend_direction: "up",
                growth_rate_percentage: 8.3,
                regions_popular: ["Thailand", "India", "Mexico"],
                related_hashtags: ["#FoodTruck", "#LocalEats", "#FoodAdventure"]
            },
            {
                hashtag: "#Baking",
                post_count: 55000000,
                trend_direction: "up",
                growth_rate_percentage: 4.7,
                regions_popular: ["France", "United States", "United Kingdom"],
                related_hashtags: ["#BakingLove", "#Desserts", "#BakeFromScratch"]
            }
        ]

    const sortedData = trending_foods?.sort((a, b) => b.growth_rate_percentage - a.growth_rate_percentage);

    return (
        <div className="grid grid-cols-12 overflow-y-scroll">
            <div className="col-span-12">
                <Carousel
                    autoPlay={true}
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    customRightArrow={<div>button</div>}
                    ssr={true}
                    slidesToSlide={1}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {uniquePosts.map((data) => (
                        <div className="rounded-md px-[8px] w-auto py-[3px] cursor-pointer my-1 text-center z-0" key={data.id}>
                            <img src={data.img} alt="img" className="w-full h-28 object-cover rounded-md" />
                        </div>
                    ))}
                </Carousel>
            </div>

            <hr className="col-span-12 w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />

            <div className="col-span-12 flex flex-wrap gap-4">
                <div>
                    <div className="px-2 py-1">
                        <h1 className="font-extrabold text-[20px]">Trending</h1>
                    </div>
                    {sortedData?.map((data, index) => {
                        const num = index + 1;
                        return (
                            <>
                                <div
                                    key={data.id}
                                    className="flex gap-2 mb-2 px-2 py-1"
                                >
                                    <div className="flex flex-col">
                                        <div className="flex items-center">
                                            <p className="text-sm font-bold">{num}.</p>
                                            <p className="text-sm font-bold px-1">{data.hashtag}</p>
                                            <p className="text-xs pr-1 flex px-2">{data.growth_rate_percentage} <span>{data.growth_rate_percentage > 0 ? <BiUpArrowAlt /> : <BiDownArrowAlt />}</span></p>
                                        </div>
                                        <div className="flex justify-start items-center">
                                            {data.related_hashtags.map((related) => (
                                                <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4 flex gap-1 underline cursor-pointer" onClick={() => navigate(`/hashtag/${related.replace("#", "")}`)}> {related}</p>
                                            ))}
                                        </div>
                                        <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4 mt-2 flex gap-1"><MdRemoveRedEye />{data.post_count}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <hr className="col-span-12 w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />

            <div className="col-span-12 flex flex-wrap gap-4 mt-2 mb-24">
                <div className="px-2">
                    <p className="font-extrabold text-[20px]">Recent Posts</p>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[-10px]">
                    {post.map((data) => (
                        <div
                            key={data.id}
                            className="flex col-span-6 flex-wrap justify-start items-center gap-1 mb-1 cursor-pointer px-2 py-1"
                        >
                            <div className="flex justify-start overflow-hidden">
                                <img
                                    alt={data.userName}
                                    src={data.profileImage}
                                    className="h-8 w-8 rounded-full object-cover"
                                    onClick={() => navigate("/otheruser/account/1a3962eb-e90f-407b-af82-76dcf360f570")}
                                />
                                <div className="flex flex-col justify-start ml-1" onClick={() => navigate("/otheruser/account/1a3962eb-e90f-407b-af82-76dcf360f570")}>
                                    <p className="text-[12px] font-bold">{data.firstName}</p>
                                    <p className="text-[12px] text-gray-800 mt-[-4px]" >{data.userName}</p>
                                </div>
                            </div>
                            <img
                                alt="userName"
                                src={data.postImage}
                                className="rounded-[4px] object-cover w-full"
                                onClick={() => navigate("/postview/c7dd6c98-4acd-4f00-8ab8-6caabe7bc045")}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
