import React, { Fragment, useEffect, useState } from "react";
import SideMenu from "../components/SideMenu";
import MainPost from "../components/otherUserAccount/MainPost";
import Media from "../components/otherUserAccount/Media";
import About from "../components/otherUserAccount/About";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOtherUserProfile } from "../redux/apislice/otherUserSlice";
import Skeleton from "react-loading-skeleton";
import { startFollow } from "../redux/apislice/profileSlice";
import toast, { Toaster } from "react-hot-toast";

const OtherAccount = () => {
    const initialLabel = "C / FE / Other user account / V1 / July 29, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate()
    const params = useParams()
    const { user_id } = params
    const dispatch = useDispatch()
    const [follow, setFollow] = useState(false);
    const [selectedTab, setSelectedTab] = useState("post");
    const [label, setLabel] = useState(initialLabel);
    const userDetails = useSelector(state => state.otherUser)
    const { otherUser, loading } = userDetails

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        switch (tab) {
            case "post":
                setLabel("C / FE / Other user account post / V1 / July 29, 2024");
                break;
            case "media":
                setLabel("C / FE / Other user account / media / V1 / July 30, 2024");
                break;
            case "about":
                setLabel("C / FE / Other user account / about / V1 / aug 02, 2024");
                break;
            default:
                setLabel(initialLabel);
        }
    };

    const handleFollow = (userId) => {
        setFollow(!follow)
        dispatch(startFollow(userId))?.then((result) => {
            if (result.error) {
                toast.success(result.error)
            } else {
                toast.success(result.payload.message)
            }
        });
    }

    useEffect(() => {
        dispatch(fetchOtherUserProfile(user_id))
    }, [dispatch, user_id])

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="sticky top-0 w-full z-50 bg-white">
                <div className="flex justify-between items-center px-2 pt-2 relative">
                    <SideMenu />
                    <button
                        onClick={() => handleFollow(user_id)}
                        className="bg-black text-white px-3 py-[2px] text-[12px] rounded-full"
                    >
                        {follow ? "Follow" : "Unfollow"}
                    </button>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <p className="text-[18px] md:text-[15px] font-bold mb-2 flex gap-[2px]"> {otherUser?.first_name || <Skeleton width={50} />} {otherUser?.last_name || <Skeleton width={50} />}</p>
                    {loading ?
                        <Skeleton circle width={80} height={80} /> :
                        <img
                            alt="img"
                            className="object-cover h-20 w-20 mb-1 rounded-full"
                            src={otherUser?.avatar_url}
                        />
                    }
                    <p className="text-[14px] md:text-[13px] font-semibold lowercase"> {otherUser?.username || <Skeleton width={50} />}</p>
                </div>

                <div className="grid grid-cols-12 items-center px-3 py-2">
                    <div className="col-span-4 text-center">
                        <p className="text-[18px] md:text-[15px] font-bold">{otherUser?.posts_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer">Posts</p>
                    </div>
                    <div className="col-span-4 text-center" onClick={() => navigate(`/otheruser/account/followers/${otherUser.id}`)}>
                        <p className="text-[18px] md:text-[15px] font-bold">{otherUser?.followers_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" >Followers</p>
                    </div>
                    <div className="col-span-4 text-center">
                        <p className="text-[18px] md:text-[15px] font-bold">{otherUser?.following_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" onClick={() => navigate(`/otheruser/account/following/${otherUser.id}`)}>Following</p>
                    </div>
                </div>

                <div className="flex items-center justify-between bg-[#d9d9d9] text-[14px] md:text-[12px] font-bold px-9 py-2">
                    <p className={selectedTab === "post" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("post")}>Posts</p>
                    <p className={selectedTab === "media" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("media")}>Media</p>
                    <p className={selectedTab === "about" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("about")}>About</p>
                </div>
            </div>

            <div className="pt-1 pb-28">
                {selectedTab === "post" && <MainPost user_id={user_id} />}
                {selectedTab === "media" && <Media />}
                {selectedTab === "about" && <About user_details={otherUser} />}
            </div>
        </Fragment>
    );
};

export default OtherAccount;
