import React from "react"
import RecoverPass from "../components/auth/RecoverPass";

const RecoverPassPage = () => {
   const lable = "C / FE / RECOVER PASSWORD PAGE / V1 / MAR 22, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   return (
      <div className="bg-[#d9d9d9] h-screen md:h-[87vh]">
         <div className="text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <RecoverPass />
      </div>
   )
}

export default RecoverPassPage