import React from 'react'
import { IoClose } from "react-icons/io5";
import { Button } from "@material-tailwind/react";
import { previousOrders } from '../../data';


const EditProductModal = ({ close, editPostId }) => {
    const editPostData = previousOrders.filter((data) => (data.productOrderNumber === editPostId));
    const { productImg, productName, productPrice, date } = editPostData[0];

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50">
            <div className="bg-white py-4 rounded-lg shadow-lg w-11/12  h-100 max-w-lg">
                <div className="flex justify-between items-center px-2 pb-1 border-b-[0.4px] border-gray-300">
                    <p className="text-[18px] font-bold">Edit Product</p>
                    <button className="bg-[#000000] hover:bg-[#222222] rounded p-1">
                        <IoClose className="cursor-pointer text-white text-[14px]" onClick={close} />
                    </button>
                </div>
                <div className="grid grid-cols-12 items-center px-2 mt-2 rounded-md">
                    <div className="col-span-12 overflow-x-scroll">
                        <img 
                            src={productImg} 
                            alt={productName} 
                            className="w-full rounded-[6px] h-40 object-cover" 
                        />
                    </div>
                    <div className="col-span-12 py-1 flex text-[10px] text-gray-600 font-bold justify-start">
                        <p>{date}</p>
                    </div>
                    <div className="col-span-12 flex text-[14px] font-bold mt-1 justify-start">
                        {productName}
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center mt-2 p-2 rounded-md">
                    <div className="col-span-6 flex text-[14px] font-bold justify-start">
                        Price
                    </div>
                    <div className="col-span-6 flex text-[16px] font-bold justify-start">
                        <input 
                            required 
                            placeholder="$price" 
                            value={productPrice} 
                            className="border-b-2 border-gray-300 focus:border-gray-500 outline-none w-full" 
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2 p-2 rounded-md">
                    <div className="col-span-6 flex text-[14px] font-bold justify-start">
                        Category
                    </div>
                    <div className="col-span-6 flex text-[16px] font-bold justify-start">
                        <input 
                            required 
                            value={"Electronics"} 
                            placeholder="Order Number" 
                            className="border-b-2 border-gray-300 focus:border-gray-500 outline-none w-full" 
                        />
                    </div>
                </div>

                <div className="flex items-center mt-2 p-2 justify-center rounded-md">
                    <Button color="black" ripple="light" className="bg-black">
                        Update Post
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default EditProductModal
