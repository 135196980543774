import React from "react";
import { post } from "../../data";
import { Carousel } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export default function EntertainmentSearch() {
    const navigate = useNavigate()
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <div className="grid grid-cols-12 overflow-y-scroll">
            <div className="col-span-12 px-2 mt-1">
                <p className="text-[18px] font-extrabold py-1">Recommended</p>
            </div>
            <div className="px-2 col-span-12 -mt-1">
                <Carousel
                    arrows={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    navigation={false}
                    infinite={false}
                    slidesToSlide={1}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {post?.map((data) => (
                        <div className="rounded-md py-[3px] cursor-pointer h-28 w-full" key={data}>
                            <img src={data.postImage} alt="img" className="object-cover  rounded-xl h-full w-full"  onClick={() => navigate("/postview/c7dd6c98-4acd-4f00-8ab8-6caabe7bc045")}/>
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className="col-span-12 px-2 mt-2 mb-28">
                <div className="w-full grid grid-cols-12">
                    <p className="col-span-12 text-[18px] font-extrabold py-1">Most watch</p>
                </div>
                <div className="w-full grid grid-cols-12 gap-3 -mt-1">
                    {post.map((data) => (
                        <div className="col-span-6 relative cursor-pointer" >
                            <div className="absolute top-1 left-[2px] flex gap-1 px-1 py-[2px] bg-gray-600 rounded-3xl justify-start items-center" onClick={() => navigate("/otheruser/account/1a3962eb-e90f-407b-af82-76dcf360f570")}>
                                <img src={data.profileImage} alt={data.firstName} className="rounded-full h-4 w-4 object-cover"/>
                                <p className="text-white text-[10px] rounded-full">{data.firstName}</p>
                            </div>

                            <img src={data.postImage} alt={data.firstName} className="rounded-[6px] w-full h-28 object-cover" onClick={() => navigate("/postview/c7dd6c98-4acd-4f00-8ab8-6caabe7bc045")}/>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}


