import React, { useState } from "react";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaRegHeart, FaHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { groupPostData } from "../../../data";



const AccountOwnerGroupPost = ({ onShare }) => {
    const navigate = useNavigate();
    const [likes, setLikes] = useState({});
    const [bookmarks, setBookmarks] = useState({});

    const handleLike = (postId) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [postId]: !prevLikes[postId],
        }));
    };

    const handleBookmark = (postId) => {
        setBookmarks((prevBookmarks) => ({
            ...prevBookmarks,
            [postId]: !prevBookmarks[postId],
        }));
    };

    return (
        <div className="px-0">
            {groupPostData.map((data) => (
                <div
                    key={data.id}
                    className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]"
                >
                    <div className="col-span-2">
                        <img
                            alt={data.userName}
                            src={data.userProfileImage}
                            className="h-8 w-8 rounded-full object-cover"
                        />
                    </div>
                    <div className="col-span-10 -ml-2">
                        <div className="flex flex-col">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                    <div className="flex items-center">
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                        <LuDot className="text-[#5c5c5c] text-[12px]" />
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data.postTime}</p>
                                    </div>
                                </div>
                                <BsThreeDotsVertical
                                    className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                />
                            </div>
                            <p
                                className="text-black text-[12px]"
                                onClick={() => navigate("/account/owner/group/postview")}
                            >
                                {data.postContent}
                            </p>
                        </div>
                        {data.postImage && (
                            <img
                                alt={data.postContent}
                                src={data.postImage}
                                onClick={() => navigate("/account/owner/group/postview")}
                                className="w-full h-auto object-cover rounded-[6px]"
                            />
                        )}
                        <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate("/account/owner/group/comment")}>
                                <FaRegComment className="text-[14px]" />
                                <p className="text-[12px]">{data.postComments}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => handleLike(data.id)}>
                                {likes[data.id] ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                                <p className="text-[12px]">{data.postLikes}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <LuRepeat2 className="text-[14px]" />
                                <p className="text-[12px]">{data.postRepost}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <FiBarChart2 className="text-[14px]" />
                                <p className="text-[12px]">{data.postImpression}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                {bookmarks[data.id] ?
                                    <FaBookmark
                                        className="text-[12px] text-blue-500 cursor-pointer"
                                        onClick={() => handleBookmark(data.id)}
                                    /> :
                                    <FaRegBookmark
                                        className="text-[12px] cursor-pointer"
                                        onClick={() => handleBookmark(data.id)}
                                    />}
                                <AiOutlineShareAlt
                                    className="text-[14px] cursor-pointer"
                                    onClick={() => onShare("https://www.post.share.com")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AccountOwnerGroupPost;
