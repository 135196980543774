import React, { Fragment, useEffect, useState } from "react";
import MainPost from "../components/post/MainPost";
import ShareModal from "../components/common/ShareModal";
import ReportModal from "../components/common/ReportModal";
import BlockModal from "../components/common/BlockModal";

const HomePage = ({ setLable }) => {
   const [sharePost, setSharePost] = useState(false);
   const [reportPost, setReportPost] = useState(false);
   const [blockUser, setBlockUser] = useState(false);

   useEffect(() => {
      setLable("C / FE / HomePage / V6 / SEP 05, 2024");
   }, [setLable]);

   return (
      <Fragment>
         <div className="absolute h-[-webkit-fill-available] w-full">
            <div className="px-2 h-[-webkit-fill-available] overflow-hidden relative">
               <MainPost 
                  setSharePost={setSharePost} 
                  setReportPost={setReportPost}
                  setBlockUser={setBlockUser} 
               />
            </div>

            {sharePost &&
               <ShareModal
                  url={"main/post/share/postId"}
                  onClose={() => setSharePost(false)}
               />
            }

            {/* Report Modal */}
            {reportPost &&
               <ReportModal
                  setReportPost={setReportPost}
               />
            }

            {/* Block Modal */}
            {blockUser &&
               <BlockModal
                  setBlockUser={setBlockUser}
               />
            }
         </div>
      </Fragment>
   )
}

export default HomePage