import { configureStore } from "@reduxjs/toolkit";
import securityReducer from "./apislice/securitySlice";
import authReducer from "./apislice/authSlice";
import profileReducer from "./apislice/profileSlice";
import postReducer from "./apislice/postSlice";
import homeReducer from "./apislice/homeSlice"
import commentReducer from "./apislice/commentSlice"
import likedPostReducer from "./apislice/LikedPostsSlice";
import totalUserReducer from "./apislice/totalUserSlice";
import otherUserProfileReducer from "./apislice/otherUserSlice";
import hashtagReducer from "./apislice/hashtagSlice";
import notificationsReducer from "./apislice/notificationsSlice";
import repostReducer from "./apislice/repostSlice";
import savedReducer from "./apislice/savedSilce";

export const store = configureStore({
    reducer: {
        security: securityReducer,
        auth: authReducer,
        profile: profileReducer,
        post: postReducer,
        home: homeReducer,
        comments: commentReducer,
        likedPost: likedPostReducer,
        totalUsers: totalUserReducer,
        otherUser: otherUserProfileReducer,
        hashTag: hashtagReducer,
        notifications: notificationsReducer,
        repost: repostReducer,
        saved: savedReducer
    },
})