import React, { Fragment } from "react";
import Header from "./Header";
import BestSellCloth from "./BestSellCloth";
import BestSellTech from "./BestSellTech";

const Popular = () => {
   const lable = "C / FE / POPULAR PAGE / V1 / MAR 30, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <Header />
         <BestSellCloth />
         <BestSellTech />
      </Fragment>
   )
}

export default Popular