import { Avatar } from "@material-tailwind/react";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { suggestedGroups } from "../../data";
import { IoCaretBackCircle, IoSearch, IoPersonAddSharp } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { MdNotificationAdd, MdReport, MdLogout } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import GroupPost from "./GroupPost";
import GroupAbout from "./GroupAbout";
import GroupMedia from "./GroupMedia";
import ShareModal from "../common/ShareModal";
import YesNoModal from "../common/YesNoModal";

const GroupJoinedHome = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [prevTab, setPrevTab] = useState('All');
    const [direction, setDirection] = useState('left');
    const [menuOpen, setMenuOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const [shareUrl, setShareUrl] = useState("http://group/joined/home");

    const navigate = useNavigate();
    const menuRef = useRef(null);
    const threeDotsRef = useRef(null);

    const handleTabChange = (newTab) => {
        if (newTab !== activeTab) {
            setDirection(newTab > activeTab ? 'right' : 'left');
            setPrevTab(activeTab);
            setActiveTab(newTab);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Recent':
                return <GroupPost onShare={handleShareClick} />;
            case 'Media':
                return <GroupMedia onShare={handleShareClick} />;
            case 'About':
                return <GroupAbout />;
            default:
                return <GroupPost onShare={handleShareClick} />;
        }
    };

    const getLabel = () => {
        switch (activeTab) {
            case 'Recent':
                return "C / FE / JOINED GROUP HOME / RECENT / JULY 18, 2024";
            case 'Media':
                return "C / FE / JOINED GROUP HOME / MEDIA / JULY 19, 2024";
            case 'About':
                return "C / FE / JOINED GROUP HOME / ABOUT / JULY 19, 2024";
            default:
                return "C / FE / JOINED GROUP HOME / V1 / JULY 18, 2024";
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current && !menuRef.current.contains(event.target) &&
            threeDotsRef.current && !threeDotsRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Label & CopyRight
    const label = getLabel();
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const handleShare = () => {
        setMenuOpen(false);
        setShareModalOpen(true);
    }

    const handleReport = () => {
        setMenuOpen(false);
        setReportModalOpen(true);
    }

    const handleLeave = () => {
        setMenuOpen(false);
        setLeaveModalOpen(true);
    }

    const handleShareClick = (url) => {
        setShareUrl(url);
        setShareModalOpen(true);
    }

    return (
        <div className="bg-white h-full overflow-y-scroll pb-24">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="relative">
                <div className="relative">
                    <img
                        alt="nature"
                        className="w-full h-[7.5rem] object-cover"
                        src="https://images.unsplash.com/photo-1483004406427-6acb078d1f2d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <div className="absolute w-full flex justify-between px-2 top-2">
                        <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                            <IoCaretBackCircle
                                onClick={() => navigate(-1)}
                                className="text-white cursor-pointer"
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <IoSearch
                                    onClick={() => navigate("/group/joined/search")}
                                    className="text-white cursor-pointer"
                                />
                            </div>
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <FiUpload
                                    onClick={() => navigate("/group/joined/upload")}
                                    className="text-white cursor-pointer"
                                />
                            </div>
                            <div
                                className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full"
                                onClick={toggleMenu}
                                ref={threeDotsRef}
                            >
                                <BsThreeDotsVertical
                                    className="text-white cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                    {menuOpen && (
                        <div
                            className="absolute top-12 right-3 bg-white text-black rounded-md border-[0.4px] border-[#adadad]"
                            ref={menuRef}
                        >
                            <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleShare}>Share</p>
                                <FaShareAlt className="h-4 w-4" />
                            </div>
                            <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleReport}>Report</p>
                                <MdReport className="h-4 w-4" />
                            </div>
                            <div className="flex items-center justify-between gap-3 px-3 py-1 hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleLeave}>Leave</p>
                                <MdLogout className="h-[14px] w-[14px]" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-2 pb-1 bg-[#0a0a12] text-white">
                    <p className="font-bold text-[23px]">Nature Water</p>
                    <p className="border border-white rounded-full px-3 text-sm inline-block">Nature</p>

                    <div className="flex justify-between items-start pt-[6px]">
                        {suggestedGroups.filter(group => group.id === 1).map((data) => (
                            <div className="flex items-center -space-x-3">
                                {data.membersDeatils.map((member) => (
                                    <Avatar
                                        key={member.id}
                                        variant="circular"
                                        alt={member.name}
                                        className="border-2 border-white h-5 w-5 cursor-pointer hover:z-10 focus:z-10"
                                        src={member.profileImage}
                                    />
                                ))}
                                <p className="pl-5 text-xs">{data.members} Members</p>
                            </div>
                        ))}
                        <div className="flex items-center gap-2">
                            <MdNotificationAdd
                                onClick={() => navigate('/group/joined/notification/settings')}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                            <IoPersonAddSharp
                                onClick={() => navigate('/group/joined/invitemembers')}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                        </div>
                    </div>

                    <p className="text-sm">serene, refreshing, pure tranquility.</p>
                </div>

                <div className="sticky top-0 w-full flex items-center justify-between px-4 py-2 bg-black z-10 text-white text-[14px] border-b-[0.4px] border-[#424242]">
                    <p
                        className={`cursor-pointer ${activeTab === 'All' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('All')}
                    >
                        All Post
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'Media' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('Media')}
                    >
                        Media
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'About' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('About')}
                    >
                        About
                    </p>
                </div>

                <div className={`mt-2 mb-4 transition-transform duration-300 ease-in-out ${activeTab !== prevTab ? (direction === 'left' ? 'animate-slideInLeft' : 'animate-slideInRight') : ''}`}>
                    {renderContent()}
                </div>
            </div>

            {shareModalOpen && (
                <ShareModal url={shareUrl} onClose={() => setShareModalOpen(false)} />
            )}
            {reportModalOpen && (
                <YesNoModal 
                    type="report"
                    heading="Submit Report" 
                    onClose={() => setReportModalOpen(false)} 
                />
            )}
            {leaveModalOpen && (
                <YesNoModal 
                    type="leave"
                    heading="Are You Sure You Want to Leave?"
                    onClose={() => setLeaveModalOpen(false)}     
                />
            )}
        </div>
    );
};

export default GroupJoinedHome;
