import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoCaretBackCircle } from "react-icons/io5";
import { suggestedGroups } from "../../data";
import { Avatar } from "@material-tailwind/react";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};

const GroupSearch = () => {
   const lable = "C / FE / GROUP SEARCH / V1 / MAY 22, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   const navigate = useNavigate();

   const groupCategory = [
      { id: 1, name: "All" },
      { id: 2, name: "Sports" },
      { id: 3, name: "Technology" },
      { id: 4, name: "Arts" },
      { id: 5, name: "Entertainment" },
      { id: 6, name: "Gaming" },
      { id: 7, name: "Politics" },
      { id: 8, name: "Business" },
      { id: 9, name: "Culture" },
      { id: 10, name: "Science" },
      { id: 11, name: "Food" },
      { id: 12, name: "Animals" },
      { id: 13, name: "Eduction" },
      { id: 14, name: "Fashion & Beauty" },
      { id: 15, name: "Health & Fitness" },
      { id: 16, name: "News" },
      { id: 17, name: "Cryptocurrency" },
      { id: 18, name: "Travel" },
   ];

   return (
      <div className="mb-28">
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
               <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="flex items-center gap-2 px-2 mt-1">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="h-8 w-8 cursor-pointer"
               />
               <input
                  type="text"
                  placeholder="Search for groups"
                  className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full text-white focus:outline-none focus:bg-[#dedede] focus:text-black bg-black"
               />
            </div>
            <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />

            <div className="px-1">
               <Carousel
                  arrows={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={false}
                  slidesToSlide={1}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               >
                  {groupCategory.map((data) => (
                     <div className="bg-black rounded-md px-[6px] py-[3px] cursor-pointer my-1 text-center w-[5rem] overflow-hidden" key={data.id}>
                        <p className="text-[10px] text-white hover:text[#] line-clamp-1">
                           {data.name}
                        </p>
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>

         <div className="mt-2 mb-5">
            {suggestedGroups.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id}>
                  <img
                     alt={data.name}
                     src={data.imgUrl}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col justify-between">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-3">
                           <p className="text-[12px] line-clamp-1">{data.name}</p>
                           <p className="text-[10px] text-gray-500">{data.members} Members</p>
                        </div>
                        <button className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black w-[50px] mt-1">
                           Join
                        </button>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {data.membersDeatils.map((member) => (
                           <Avatar
                              key={member.id}
                              variant="circular"
                              alt={member.name}
                              className="border-2 border-white h-8 w-8 cursor-pointer hover:z-10 focus:z-10"
                              src={member.profileImage}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default GroupSearch