import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const AboutusPage = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const navigate = useNavigate()
    const newsDetails = {
        id: 1,
        img: "https://media.istockphoto.com/id/1221653457/photo/close-up-of-a-touchscreen-social-media-concept.webp?b=1&s=612x612&w=0&k=20&c=z9bxqPbfNhKM6jWCMhvrbvSH6JRY32kJASVgnfdVO2c=",
        headline: "ABOUT US",
    }

    const lable = "C / FE / ABOUT US / PAGE / V1 / SEP 16, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
    const scrollableDivRef = useRef(null);

    const scrollToTop = () => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <div className="mb-48 pb-4 h-full">
            <div ref={scrollableDivRef} className="overflow-y-scroll h-screen pb-2">
                <div className="text-center bg-[#d9d9d9]">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[9px]">{copyright}</p>
                </div>
                <div className="relative">
                    <img src={newsDetails.img} alt="newsDetails" className="h-48 w-full object-cover" />

                    <div className="absolute w-full flex justify-between px-2 top-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer text-[18px]"
                        />
                    </div>

                </div>
                <div className="container mx-auto text-center mt-2 px-2">
                    <p className="text-[13px] leading-4 text-gray-600 mb-4">
                        Our company is committed to delivering the best products and services to our customers.
                        We have a team of skilled professionals who are passionate about what they do.
                    </p>

                    <div className="mb-4">
                        <h3 className="text-[22px] font-bold mb-1">Our History</h3>
                        <p className="text-[13px] leading-4 text-gray-600 mb-4">
                            We started our journey in [Year] and have grown exponentially since then. Our company has achieved several milestones, and we continue to expand our reach globally.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-[22px] font-bold mb-1">Our Mission</h3>
                        <p className="text-[13px] leading-4 text-gray-600 mb-4">
                            Our mission is to provide innovative solutions that meet the needs of our clients. We are dedicated to excellence and constantly strive to exceed expectations.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-[22px] font-bold mb-1">Our Values</h3>
                        <p className="text-[13px] leading-4 text-gray-600 mb-4">
                            We value integrity, innovation, and customer satisfaction. These core principles guide everything we do, ensuring that we deliver quality in every project.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-[22px] font-bold mb-1">Meet Our Team</h3>
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className="px-2 flex justify-center flex-col items-center ">
                                <img src="https://images.unsplash.com/photo-1609866975749-2238afebfa27?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGpvaG4lMjBkb2V8ZW58MHx8MHx8fDA%3D" alt="Team Member 1" className="rounded-lg shadow-lg mb-4 h-40 object-cover w-full" />
                                <h4 className="text-xl font-semibold">John Doe</h4>
                                <p className="text-gray-600">CEO & Founder</p>
                            </div>
                            <div className="px-2 flex justify-center flex-col items-center">
                                <img src="https://images.unsplash.com/photo-1662925518821-1834a9fa2f44?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fEphbmUlMjBTbWl0aHxlbnwwfHwwfHx8MA%3D%3D" alt="Team Member 2" className="rounded-lg shadow-lg mb-4 h-40 object-cover w-full" />
                                <h4 className="text-xl font-semibold">Jane Smith</h4>
                                <p className="text-gray-600">COC</p>
                            </div>
                            <div className="px-2 flex justify-center flex-col items-center">
                                <img src="http://ts2.mm.bing.net/th?id=OIP.2NO-SpJThyOkBCRwGSkKjAAAAA&pid=15.1" alt="Team Member 3" className="rounded-lg shadow-lg mb-4 h-40 object-cover w-full" />
                                <h4 className="text-xl font-semibold">Mike Johnson</h4>
                                <p className="text-gray-600">Head of Marketing</p>
                            </div>
                            <div className="px-2 flex justify-center flex-col items-center">
                                <img src="https://th.bing.com/th/id/OIP._NPd3MvLzAhteqZhOSMlggHaE8?w=276&h=184&c=7&r=0&o=5&pid=1.7" alt="Team Member 4" className="rounded-lg shadow-lg mb-4 h-40 object-cover w-full" />
                                <h4 className="text-xl font-semibold">Emily Davis</h4>
                                <p className="text-gray-600">Lead Developer</p>
                            </div>
                        </Carousel>
                    </div>

                    <div className="mt-12">
                        <h3 className="text-[22px] font-bold mb-1">Client Testimonials</h3>
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-20-px"
                        >
                            <div className="px-2">
                                <p className="text-[14px] italic text-gray-600">
                                    "This company provided us with outstanding service and the best quality products. Highly recommend!"
                                </p>
                                <h4 className="text-[16px] font-semibold my-1">- Client A</h4>
                            </div>
                            <div className="px-2">
                                <p className="text-[14px] italic text-gray-600">
                                    "The team was professional, and they delivered beyond our expectations. We"re very satisfied with the results."
                                </p>
                                <h4 className="text-[16px] font-semibold my-1">- Client B</h4>
                            </div>
                            <div className="px-2">
                                <p className="text-[14px] italic text-gray-600">
                                    "Innovative solutions and top-notch customer service. We couldn"t be happier with our choice."
                                </p>
                                <h4 className="text-[16px] font-semibold my-1">- Client C</h4>
                            </div>
                        </Carousel>
                    </div>
                    <footer className="bg-gray-900 text-white px-2">
                        <div className="container mx-auto ">
                            <div className="flex flex-wrap justify-between">
                                <div className="w-full mt-4 mb-5">
                                    <h3 className="text-[18px] font-bold mb-1">Quick Links</h3>
                                    <ul className="text-gray-400">
                                        <li className="mb-2"><a href="#about" className="hover:text-white text-[14px]" onClick={scrollToTop}>About Us</a></li>
                                        <li className="mb-2"><a href="#services" className="hover:text-white text-[14px]" onClick={() => navigate("/terms")}>Services</a></li>
                                        <li className="mb-2"><a href="#contact" className="hover:text-white text-[14px]" onClick={() => navigate("/contactus")}>Contact Us</a></li>
                                        <li className="mb-2"><a href="#careers" className="hover:text-white text-[14px]">Careers</a></li>
                                    </ul>
                                </div>
                                <div className="w-full mb-5">
                                    <h3 className="text-[18px] font-bold mb-1">Contact Information</h3>
                                    <ul className="text-gray-400 flex justify-center items-center flex-col">
                                        <li className="mb-2 flex items-center text-[14px]"><FaPhone className="mr-3" /> (123) 456-7890</li>
                                        <li className="mb-2 flex items-center text-[14px]"><FaEnvelope className="mr-3" /> contact@company.com</li>
                                        <li className="mb-2 flex items-center text-[14px]">123 Main Street, City, Country</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between mt-2">
                                <div className="w-full mb-2">
                                    <h3 className="text-[18px] font-bold mb-1">Subscribe to Our Newsletter</h3>
                                    <form className="flex">
                                        <input type="email" placeholder="Enter your email" className="w-full p-2 text-gray-900 rounded-l-lg outline-none placeholder:text-[14px]" />
                                        <button disabled className="bg-pink-600 text-white p-2 rounded-r-lg text-[14px]">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default AboutusPage;



