import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoAddOutline, IoCaretBackCircle, IoPersonAddSharp, IoSearch } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { MdReport, MdLogout, MdNotificationAdd } from "react-icons/md";
import { suggestedGroups } from "../../../data";
import { IoMdAddCircle } from "react-icons/io";
import ShareModal from "../../common/ShareModal";
import { Avatar } from "@material-tailwind/react";
import AccountOwnerGroupAbout from "./AccountOwnerGroupAbout";
import AccountOwnerGroupPost from "./AccountOwnerGroupPost";
import AccountOwnerGroupMedia from "./AccountOwnerGroupMedia";
import YesNoModal from "../../common/YesNoModal";


const GroupOwnerHome = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [prevTab, setPrevTab] = useState('All');
    const [direction, setDirection] = useState('left');
    const [menuOpen, setMenuOpen] = useState(false);
    const [showShareModel, setShareModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);

    const navigate = useNavigate();
    const menuRef = useRef(null);
    const threeDotsRef = useRef(null);
    const handleShareClick = (url) => {
        setShareModalOpen(true);
    }

    const handleTabChange = (newTab) => {
        if (newTab !== activeTab) {
            setDirection(newTab > activeTab ? 'right' : 'left');
            setPrevTab(activeTab);
            setActiveTab(newTab);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'GroupInfo':
                return <AccountOwnerGroupAbout />
            case 'Media':
                return <AccountOwnerGroupMedia onShare={handleShareClick} />
            default:
                return <AccountOwnerGroupPost onShare={handleShareClick} />;
        }
    };

    const getLabel = () => {
        switch (activeTab) {
            case 'Media':
                return "C-FE-ACCOUNT OWNER GROUP HOME-MEDIA-AUGUST-17, 2024";
            case 'GroupInfo':
                return "C-FE-ACCOUNT OWNER GROUP HOME-GroupInfo -AUGUST 16, 2024";
            default:
                return "C-FE-ACCOUNT OWNER GROUP HOME-V1-AUGUST 16, 2024";
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current && !menuRef.current.contains(event.target) &&
            threeDotsRef.current && !threeDotsRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    const handleReport = () => {
        setMenuOpen(false);
        setReportModalOpen(true);
    }
    const handleLeave = () => {
        setMenuOpen(false);
        setLeaveModalOpen(true);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // Label & CopyRight
    const label = getLabel();
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className="bg-white h-full overflow-y-scroll overflow-x-hidden relative ">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="relative">
                <div className="relative">
                    <img
                        alt="nature"
                        className="w-full h-[10rem] object-cover opacity-75"
                        src="https://images.unsplash.com/photo-1483004406427-6acb078d1f2d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

                    />
                    <div className="absolute top-0 left-2 flex justify-start items-center w-full h-full">
                        <img alt="nature"
                            className="w-[5rem] h-[5rem] object-cover rounded-full border-2 border-white "
                            src={suggestedGroups[0].imgUrl} />
                    </div>

                    <div className="absolute w-full flex justify-between px-2 top-2">
                        <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                            <IoCaretBackCircle
                                onClick={() => navigate(-1)}
                                className="text-white cursor-pointer"
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <IoSearch
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate("/account/owner/group/search")}
                                />
                            </div>

                            <div
                                className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full"
                                onClick={toggleMenu}
                                ref={threeDotsRef}
                            >
                                <BsThreeDotsVertical
                                    className="text-white cursor-pointer"
                                />
                            </div>
                        </div>
                        {menuOpen && (
                            <div
                                className="absolute top-12 right-3 bg-white text-black rounded-md border-[0.4px] border-[#adadad]"
                                ref={menuRef}
                            >
                                <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold" onClick={() => setShareModalOpen(true)}>
                                    <p className="text-[13px] cursor-pointer">Share</p>
                                    <FaShareAlt className="h-4 w-4" />
                                </div>
                                <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                                    <p className="text-[13px] cursor-pointer" onClick={() => handleReport()}>Report</p>
                                    <MdReport className="h-4 w-4" />
                                </div>
                                <div className="flex items-center justify-between gap-3 px-3 py-1 hover:font-bold">
                                    <p className="text-[13px] cursor-pointer" onClick={() => handleLeave()}>Leave</p>
                                    <MdLogout className="h-[14px] w-[14px]" />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex gap-2 absolute bottom-0 w-full  justify-between px-2">
                        <p className="font-bold text-[23px] text-white shadow-md">Nature Birds</p>
                        <div className="flex items-center justify-end w-24 gap-2">
                            <MdNotificationAdd
                                onClick={() => navigate("/account/owner/group/notification/settings")}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                            <IoPersonAddSharp
                                onClick={() => navigate("/account/owner/group/invitemembers")}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="px-2 pb-1 bg-[#0a0a12] text-white">
                    <p className="text-sm line-clamp-2 pt-2">{suggestedGroups[0]?.desc}</p>
                    <div className="flex justify-between items-start pt-[6px]">
                        {suggestedGroups.filter(group => group.id === 2).map((data) => (
                            <div className="flex items-center -space-x-3">
                                {data.membersDeatils.map((member) => (
                                    <Avatar
                                        key={member.id}
                                        variant="circular"
                                        alt={member.name}
                                        className="border-2 border-white h-5 w-5 cursor-pointer hover:z-10 focus:z-10"
                                        src={member.profileImage}
                                    />
                                ))}
                                <p className="pl-5 text-xs">{data.members} Members</p>
                            </div>
                        ))}

                    </div>
                    <div className="flex flex-wrap mt-1 gap-2">
                        {["#Nimal", "#Nature", "#Birds", "#Nature", "#Nature", "#Birds"].slice(0, 3).map((data, index) => (
                            <p key={index} className="border-2 border-white rounded-full px-3 text-sm inline-block text-center cursor-pointer hover:bg-blue-500">{data}</p>
                        ))}

                        <IoMdAddCircle
                            className="text-white cursor-pointer  rounded-full p-1 text-[24px]"
                        />
                    </div>
                    <div className="flex justify-between items-start pt-[6px]">

                    </div>
                </div>
                <div className="sticky top-0 w-full flex items-center justify-between px-4 py-2 bg-black z-10 text-white text-[14px] border-b-[0.4px] border-[#424242]">
                    <p
                        className={`cursor-pointer ${activeTab === 'All' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('All')}
                    >
                        All Post
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'Media' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('Media')}
                    >
                        Media
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'GroupInfo' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('GroupInfo')}
                    >
                        GroupInfo
                    </p>
                </div>
                <div className="relative mb-28">
                    <div className={`mt-2 mb-4 transition-transform duration-300 ease-in-out ${activeTab !== prevTab ? (direction === 'left' ? 'animate-slideInLeft' : 'animate-slideInRight') : ''}`}>
                        {renderContent()}
                    </div>
                    {activeTab === "All" &&
                        <div onClick={() => navigate("/account/owner/group/upload")} className="sticky bottom-40 md:bottom-28  h-10 w-10 z-10  text-white text-[14px] float-end mx-3 cursor-pointer">
                            <div className="w-full h-full flex justify-end">
                                <div className="h-10 w-10 rounded-full bg-light-blue-500 flex justify-center items-center">
                                    <IoAddOutline className="text-white text-[1.3rem]" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showShareModel && (
                <ShareModal url={"http://group/owner/account"} onClose={() => setShareModalOpen(false)} />
            )}
            {reportModalOpen && (
                <YesNoModal
                    type="report"
                    heading="Submit Report"
                    onClose={() => setReportModalOpen(false)}
                />
            )}
            {leaveModalOpen && (
                <YesNoModal
                    type="leave"
                    heading="Are You Sure You Want to Leave?"
                    onClose={() => setLeaveModalOpen(false)}
                />
            )}
        </div>
    );
};

export default GroupOwnerHome;


