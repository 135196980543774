import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";

const mainUrl = REACT_APP_MAIN_URL;


export const getAllComments = createAsyncThunk(
    "getAllComments",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/user_commented_posts/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }

);

/* post comment section */
export const getComments = createAsyncThunk(
    "getComments",
    async (post_Id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/${post_Id}/comments/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }

);
export const createComment = createAsyncThunk(
    "createComment",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const createComment = { body: data.body }
            const response = await axios.post(`${mainUrl}/api/post/comment/${data.post_id}/`, createComment, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateComment = createAsyncThunk(
    "updateComment",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const updateComment = { body: data.body };
            const response = await axios.patch(`${mainUrl}/api/post/update_comment/${data.comment_Id}/`, updateComment, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const daleteComment = createAsyncThunk(
    "daleteComment",
    async (comment_Id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/post/delete_comment/${comment_Id}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

/* post comment reply section */
export const getReply = createAsyncThunk(
    "getReply",
    async (comment_Id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/comments/${comment_Id}/replies/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const createReply = createAsyncThunk(
    "createReply",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/comment/${data.comment_Id}/reply/`, { body: data.body }, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateReply = createAsyncThunk(
    "updateReply",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const reply = { body: data.body };
            const response = await axios.patch(`${mainUrl}/api/post/comment/${data.reply_Id}/reply_update/`, reply, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteReply = createAsyncThunk(
    "deleteReply",
    async (reply_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/post/comment/${reply_id}/delete_reply/`, config); return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const commentSlice = createSlice({
    name: "comments",
    initialState: {
        loading: false,
        data: [],
        replyData: [],
        error: null,
    },
    extraReducers: (builder) => {

        // Fetch All Comments Data
        builder.addCase(getComments.pending, (state) => {
            state.loading = true;
            state.data = [];
            state.error = null;
        });
        builder.addCase(getComments.fulfilled, (state, action) => {
            state.loading = false;
            state.data = Array.isArray(action.payload) ? action.payload : [];
            state.error = null;
        });
        builder.addCase(getComments.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        });

        // Fetch All Comments Replies Data
        builder.addCase(getReply.pending, (state) => {
            state.replyData = [];
            state.error = null;
        });
        builder.addCase(getReply.fulfilled, (state, action) => {
            state.replyData = action.payload
            state.error = null;
        });
        builder.addCase(getReply.rejected, (state, action) => {
            state.replyData = [];
            state.error = action.payload;
        });
        // Fetch Delete Reply Data
        builder.addCase(deleteReply.pending, (state) => {
            state.error = null;
        });
        builder.addCase(deleteReply.fulfilled, (state) => {
            state.error = null;
        });
        builder.addCase(deleteReply.rejected, (state, action) => {
            state.error = action.payload;
        });
        // Fetch All Comments Data
        builder.addCase(getAllComments.pending, (state) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getAllComments.fulfilled, (state, action) => {
            state.error = null;
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(getAllComments.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
    },
});

export default commentSlice.reducer;
