import React from "react";
import SideMenu from "./SideMenu";
import { FaUser } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import searchImg from "../assets/172dde859a12a003ddccdab734ccd292.png";
import groupImg from "../assets/6a3f9dddc7da9ae0f120325b9fb73e0a.png";
import followingImg from "../assets/778f4647fea07c6a1f2c3e22629806d5.png";
import foryouImg from "../assets/a1ba5f5bb19c524366e4f52a4e0525b4.png";
import marketPlaceImg from "../assets/d6c48bf530eb09e5df5373bd6ae3703d.png";
import { useNavigate } from "react-router-dom";

const Header = ({ lable }) => {
   const navigate = useNavigate();

   return (
      <div className="z-30">
         <div className="text-center pb-[2px] bg-[#d9d9d9]">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc</p>
         </div>

         <div className="bg-[#FF9900] pt-[6px] pb-[6px]">
            <div className="flex justify-between items-center px-2">
               <SideMenu />
               <button 
                  className="bg-black text-white flex items-center text-[16px] md:text-[12px] rounded-full px-[8px] md:px-[6px] py-[2px] md:py-[1px]"
                  onClick={() => navigate("/group")}
               >
                  <span className="pr-1">
                     <img alt="groupImg" src={groupImg} className="h-[22px] w-[22px] md:h-[16px] md:w-[16px]" />
                  </span>
                  Groups
               </button>
               <button 
                  className="bg-black text-white flex items-center text-[16px] md:text-[12px] rounded-full px-[8px] md:px-[6px] py-[2px] md:py-[1px]"
                  onClick={() => navigate("/account")}
               >
                  <span className="pr-1">
                     <FaUser className="h-[14px] w-[14px] md:h-[10px] md:w-[10px]" />
                  </span>
                  Account
               </button>
               <button 
                  className="bg-black text-white flex items-center text-[16px] md:text-[12px] rounded-full px-[8px] md:px-[6px] py-[2px] md:py-[1px]" 
                  onClick={() => navigate("/message")}
               >
                  <span className="pr-1">
                     <FaMessage className="h-[14px] w-[14px] md:h-[10px] md:w-[10px]" />
                  </span>
                  Messages
               </button>
               <img
                  alt="searchImg"
                  src={searchImg}
                  className="h-[26px] w-[26px] md:h-5 md:w-5 cursor-pointer"
                  onClick={() => navigate("/search")}
               />
            </div>

            <hr className="w-full col-span-12 mt-[6px] mb-[6px] h-[0.5px] bg-gray-800 border-0" />

            <div className="flex justify-between items-center px-2">
               <button className="text-[16px] md:text-[12px] font-bold cursor-pointer flex items-center" onClick={() => navigate("/account/following")}>
                  <span className="pr-1">
                     <img alt="followingImg" src={followingImg} className="h-[24px] w-[24px] md:h-5 md:w-5" />
                  </span>
                  Following
               </button>
               <button className="text-[16px] md:text-[12px] font-bold cursor-pointer flex items-center" onClick={() => navigate("/foryou")}>
                  <span className="pr-1">
                     <img alt="foryouImg" src={foryouImg} className="h-[24px] w-[24px] md:h-5 md:w-5" />
                  </span>
                  For you
               </button>
               <button className="text-[16px] md:text-[12px] font-bold cursor-pointer flex items-center" onClick={() => navigate("/marketplace")}>
                  <span className="pr-1">
                     <img alt="marketPlaceImg" src={marketPlaceImg} className="h-[24px] w-[24px] md:h-5 md:w-5" />
                  </span>
                  Market Place
               </button>
            </div>
         </div>
      </div>
   )
}

export default Header