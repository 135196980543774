import React from "react"
import LikedPosts from "../components/account/LikedPosts";
import { IoCaretBackCircle } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function LikedPostsPage() {
    const navigate = useNavigate();
    const lable = "C / FE / Liked posts page / V2 / SEP 17, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className="px-0 pb-20">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px]">{copyright}</p>
                <div className="sticky top-0 w-full grid grid-cols-12 px-2 py-2 bg-black z-10 text-gray-400 text-[14px] border-b-[0.4px] border-[#424242]">
                    <div className="col-span-2 flex justify-start items-center">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer text-[20px]"
                        />
                    </div>
                    <div className="col-span-8 flex justify-center text-center">
                        <p className="cursor-pointer text-[14px] items-center font-extrabold">
                            Liked Posts <span className="text-[1rem]">👍</span>
                        </p>
                    </div>
                    <p className="cursor-pointer col-span-2 flex justify-end items-center">
                        <IoSearch className="text-[20px] text-white" />
                    </p>
                </div>
                <LikedPosts />
            </div>
        </div>
    )
}
