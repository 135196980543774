import React, { Fragment } from "react";
import Header from "./Header";
import BiggestDiscount from "./BiggestDiscount";
import MultiPackDiscount from "./MultiPackDiscount";
import LimitedTimeDiscount from "./LimitedTimeDiscount";

const Discounted = () => {
   const lable = "C / FE / DISCOUNTED PAGE / V1 / MAR 30, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <div className="mb-24">
            <Header />
            <BiggestDiscount />
            <MultiPackDiscount />
            <LimitedTimeDiscount />
         </div>
      </Fragment>
   )
}

export default Discounted