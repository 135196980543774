import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const fetchOtherUserProfile = createAsyncThunk(
    "fetchOtherUserProfile",
    async (user_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/profile/${user_id}`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const otherUserProfileSlice = createSlice({
    name: "otherUserProfile",
    initialState: {
        loading: false,
        otherUser: {},
        error: null,
    },
    extraReducers: (builder) => {
        // Fetch OtherUserProfile
        builder.addCase(fetchOtherUserProfile.pending, (state) => {
            state.loading = true;
            state.otherUser = null;
            state.error = null;
        });
        builder.addCase(fetchOtherUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.otherUser = action.payload;
            state.error = null;
        });
        builder.addCase(fetchOtherUserProfile.rejected, (state) => {
            state.loading = false;
            state.otherUser = null;
            state.error = true;
        });
    }
});

export default otherUserProfileSlice.reducer;