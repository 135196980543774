import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HiMenu } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { FaListCheck, FaBagShopping } from "react-icons/fa6";
import { IoCloseOutline, IoCloseCircle } from "react-icons/io5";
import { BiSupport, BiSolidMessageSquareDetail } from "react-icons/bi";
import { MdPrivacyTip, MdBookmarkAdded, MdOutlinePostAdd } from "react-icons/md";

const RightSideMenu = () => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const navigate = useNavigate();

   const openDrawer = () => setIsDrawerOpen(true);

   const closeDrawer = () => {
      setIsDrawerOpen(false);
   }

   return (
      <Fragment>
         <div className="cursor-pointer" onClick={openDrawer}>
            {isDrawerOpen ? (
               <IoCloseOutline className="text-[25px]" />
            ) : (
               <HiMenu className="text-[25px]" />
            )}
         </div>
         {isDrawerOpen && (
            <div className="absolute top-0 right-0 w-full h-[100vh] bg-gray-900 bg-opacity-70 z-50" onClick={closeDrawer}></div>
         )}
         {/* sidemenu start */}
         <div className={`absolute top-0 right-0 h-screen bg-black z-50 overflow-y-scroll transform transition-transform ${isDrawerOpen ? "translate-x-0 duration-500 w-[12rem]" : "translate-x-full duration-500 w-0"}`}>
            <div className="px-4 py-4">

               <div className="flex justify-between items-center mb-2">
                  <p className="text-white font-bold text-xl cursor-pointer">
                     SETTINGS
                  </p>
                  <IoCloseCircle
                     className="h-6 w-6 cursor-pointer text-white hover:text-gray-500"
                     onClick={closeDrawer}
                  />
               </div>

               <hr className="w-full mt-2 h-[0.1px] bg-gray-800 border-0" />

               {/* user main menu start */}
               <div className="flex flex-col gap-y-[10px] mt-3">
                  <div className="flex items-center justify-start font-bold text-white gap-2">
                     <FaBagShopping className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" onClick={() => navigate("/marketplace/pastorders")}>Your Orders</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2">
                     <MdOutlinePostAdd className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" onClick={() => navigate("/marketplace/productpost")}>Your Posts</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2">
                     <BiSolidMessageSquareDetail className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" onClick={() => navigate("/message")} >Messages</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2">
                     <FaListCheck className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" onClick={() => navigate("/marketplace/favorite")} >Favorite</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2">
                     <FaFilter className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" onClick={() => navigate("/marketplace/filter")}>Filter Search</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2" onClick={() => navigate("/marketplace/customersupport")}>
                     <BiSupport className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]" >Customer Support</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2" onClick={() => navigate("/marketplace/advertise")}>
                     <RiAdvertisementFill className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]">Advertise</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2" onClick={() => navigate("/privacy")}>
                     <MdPrivacyTip className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]">Privacy Policy</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-2" onClick={() => navigate("/terms")}>
                     <MdBookmarkAdded className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[14px]">Terms and Condition</p>
                  </div>
               </div>
               {/* user main menu end */}
            </div>
         </div>
         {/* sidemenu end */}
      </Fragment>
   )
}

export default RightSideMenu