import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaUserGroup } from "react-icons/fa6";
import { HiMenu, HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaSearch, FaHeart, FaUserCircle } from "react-icons/fa";
import { IoCloseOutline, IoCloseCircle, IoSettings } from "react-icons/io5";

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { whatsHappening } from "../data";
import { useNavigate } from "react-router-dom";

import { FaShop } from "react-icons/fa6";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaBookmark } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, followersCount, followingCount, startFollow } from "../redux/apislice/profileSlice";
import { BiRepost } from "react-icons/bi";
import { fetchTotalUser } from "../redux/apislice/totalUserSlice";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";


const SideMenu = () => {
   const dispatch = useDispatch();
   const [userDetails, setUserDetails] = useState(null);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [open, setOpen] = useState(0);
   const [whoToFollow, setWhoToFollow] = useState([]);
   const [menuPostId, setMenuPostId] = useState(null);
   const menuRef = useRef(null);

   const navigate = useNavigate();

   const userData = useSelector(state => state.totalUsers);
   const { loading, totalUser } = userData

   const profile = useSelector(state => state.profile);

   console.log(totalUser, "totalUser");

   const openDrawer = () => setIsDrawerOpen(true);

   const closeDrawer = () => {
      setIsDrawerOpen(false);
      setOpen(0);
   }

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
      if (value === 2) {
         dispatch(fetchTotalUser());
      };
   };

   const handleNavigate = (path) => {
      navigate(path);
      closeDrawer();
   }

   const handleFollow = (userId) => {
      try {
         dispatch(startFollow(userId))?.then((result) => {
            if (result.error) {
               toast.success(result.error)
            } else {
               toast.success(result.payload.message)
            }
         });
      } catch (error) {
         console.error("error", error);
      }
   }


   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuPostId(null); // Close menu if clicked outside
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   useEffect(() => {
      dispatch(fetchProfile());
      dispatch(followingCount());
      dispatch(followersCount());
   }, [dispatch]);

   useEffect(() => {
      setUserDetails(profile.user);
   }, [profile]);

   useEffect(() => {
      if (totalUser) {
         const sortedData = [...totalUser].sort((a, b) => b.followers_count - a.followers_count);
         setWhoToFollow(sortedData);
      }
   }, [totalUser]);

   return (
      <Fragment>
         <Toaster position="top-right" />
         <div className="cursor-pointer" onClick={openDrawer}>
            {isDrawerOpen ? (
               <IoCloseOutline className="h-6 w-6" />
            ) : (
               <HiMenu className="h-[28px] w-[28px] md:h-6 md:w-6" />
            )}
         </div>
         {isDrawerOpen && (
            <div className="absolute top-0 left-0 w-full h-[100vh] bg-gray-900 bg-opacity-70 z-50" onClick={closeDrawer}></div>
         )}
         {/* sidemenu start */}
         <div className={`absolute top-0 left-0 h-screen bg-black z-50 w-[15rem] overflow-y-scroll transition-transform transform ${isDrawerOpen ? "translate-x-0 duration-500" : "-translate-x-full duration-500"}`}>
            <div className="px-4 py-4">

               <div className="flex justify-between items-center mb-2">
                  <p
                     onClick={() => handleNavigate("/home")}
                     className="text-white font-bold text-xl cursor-pointer uppercase"
                  >
                     Creative Logo
                  </p>
                  <IoCloseCircle
                     className="h-6 w-6 cursor-pointer text-white hover:text-gray-500"
                     onClick={closeDrawer}
                  />
               </div>

               {/* user profile section start */}
               <img
                  alt="img"
                  onClick={() => handleNavigate("/account")}
                  src={userDetails?.avatar_url}
                  className="h-10 w-10 object-cover rounded-full cursor-pointer"
               />

               <p className="font-bold text-white text-[15px] mt-2 cursor-pointer" onClick={() => handleNavigate("/account")}>
                  {userDetails?.first_name} {userDetails?.last_name}
               </p>
               <p className="text-gray-600 text-[13px] mt-[-4px] cursor-pointer" onClick={() => handleNavigate("/account")}>{userDetails?.username}</p>

               <div className="flex gap-3 text-[12px] mt-2">
                  <div className="flex gap-1">
                     <p className="text-white">{profile.followingCount.following_count}</p>
                     <p className="text-gray-600 cursor-pointer" onClick={() => handleNavigate("/account/following")}>Following</p>
                  </div>
                  <div className="flex gap-1">
                     <p className="text-white"> {profile.followersCount.followers_count}</p>
                     <p className="text-gray-600 cursor-pointer" onClick={() => handleNavigate("/account/followers")}>Followers</p>
                  </div>
               </div>
               {/* user profile section end */}

               <hr className="w-full mt-2 h-[0.1px] bg-gray-800 border-0" />

               {/* user main menu start */}
               <div className="flex flex-col gap-y-2 mt-3">
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <FaUserCircle className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/account")}>ACCOUNT PROFILE</p>
                  </div>
                  {/* <div className="flex items-center justify-start font-bold text-white gap-3">
                     <MdMessage className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]">CHIRP REVIEWS</p>
                  </div> */}
                  <div className="flex items-center justify-start font-bold text-white gap-3" onClick={(() => handleNavigate("/liked/posts"))}>
                     <FaHeart className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]">LIKED POSTS</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <BiRepost className="cursor-pointer text-[1.3rem] hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/reposts")}>REPOSTS</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <FaBookmark className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/save/posts")}>SAVED POSTS</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <IoSettings className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/settings")}>SETTINGS AND PRIVACY</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <RiUserSettingsFill className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/yourfeed/setting")}>YOUR FEED SETTINGS</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <FaUserGroup className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/group/list")}>YOUR GROUPS</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <FaShop className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/marketplace")}>MARKETPLACE</p>
                  </div>
                  <div className="flex items-center justify-start font-bold text-white gap-3">
                     <FaSearch className="cursor-pointer hover:text-gray-500" />
                     <p className="cursor-pointer hover:text-gray-500 text-[12px]" onClick={() => handleNavigate("/search")}>EXPLORE</p>
                  </div>
               </div>
               {/* user main menu end */}

               <hr className="w-full mt-3 h-[0.1px] bg-gray-800 border-0" />

               {/* what's happening start */}
               <div className="flex flex-col mt-3">
                  <div className="flex justify-between items-center">
                     <p className="text-white cursor-pointer text-[15px] hover:text-gray-500" onClick={() => handleOpen(1)}>What's Happening</p>
                     <ChevronDownIcon
                        strokeWidth={2.5}
                        onClick={() => handleOpen(1)}
                        className={`text-white hover:text-gray-500 h-4 w-4 cursor-pointer transition-transform ${open === 1 ? "rotate-180" : ""}`}
                     />
                  </div>
                  {open === 1 &&
                     <div className="mt-1">
                        {whatsHappening.map((data) => (
                           <div className="flex justify-between py-2" key={data.id}>
                              <div className="flex flex-col">
                                 <p className="text-[11px] text-gray-600">{data.topic}</p>
                                 <p className="text-[12px] font-bold text-white cursor-pointer" onClick={() => handleNavigate(`/hashtag/${data.hashTag.replace('#', '')}`)}>{data.hashTag}</p>
                                 <p className="text-[11px] text-gray-600">{data.posts} posts</p>
                              </div>
                              <div className="relative">
                                 <HiOutlineDotsHorizontal className="text-gray-700 text-[20px] mt-[-3px] cursor-pointer" onClick={() => setMenuPostId(data.id)} />
                                 {menuPostId === data?.id && (
                                    <div ref={menuRef} className="absolute w-28  right-0 z-50">
                                       <div className="bg-[#FFFFFF] flex flex-col rounded-[5px]">
                                          <div className="flex gap-2 justify-between items-center   p-1 cursor-pointer">
                                             <p className="text-[10px] font-bold hover:text-green-500" onClick={() => setMenuPostId(null)}>Not Interested</p>
                                          </div>
                                          <hr className="h-[0.8px] bg-gray-500 border-0" />
                                          <div className="flex gap-2 justify-between items-center p-1 cursor-pointer">
                                             <p className="text-[10px] font-bold  hover:text-red-500" onClick={() => setMenuPostId(null)}>Spam or Harmfull</p>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </div>

                           </div>
                        ))}
                        <button
                           onClick={() => handleNavigate("/trending/hashtag")}
                           className="bg-white text-black w-full text-[12px] mb-5 py-1 rounded-full"
                        >
                           View More
                        </button>
                     </div>
                  }
               </div>
               {/* what's happening end */}

               {/* who to follow start */}
               <div className="flex flex-col mt-2">
                  <div className="flex justify-between items-center">
                     <p className="text-white cursor-pointer text-[15px] hover:text-gray-500" onClick={() => handleOpen(2)}>Who To Follow</p>
                     <ChevronDownIcon
                        strokeWidth={2.5}
                        onClick={() => handleOpen(2)}
                        className={`text-white hover:text-gray-500 h-4 w-4 cursor-pointer transition-transform ${open === 2 ? "rotate-180" : ""}`}
                     />
                  </div>
                  {open === 2 &&
                     <div className="mt-1">
                        {loading && [1, 2, 3].map((index) => (
                           <div className="mb-1 p-1" key={index}>
                              <div className="grid grid-cols-12 h-10 gap-x-2">
                                 <div className="col-span-1 rounded-full flex justify-center items-center">
                                    <Skeleton height={20} width={20} style={{ borderRadius: "50%" }} />
                                 </div>
                                 <div className="col-span-1 h-6">
                                 </div>
                                 <div className="col-span-7 h-6 -ml-1">
                                    <div className="flex flex-col">
                                       <Skeleton width={50} height={10} />

                                       <Skeleton width={80} height={10} />
                                    </div>
                                 </div>
                                 <div className="col-span-3 flex justify-end items-center">
                                    <Skeleton width={50} />
                                 </div>
                              </div>
                           </div>
                        ))}
                        {whoToFollow?.map((data, index) => (
                           <>
                              {index < 3 && <div className="py-2 grid grid-cols-12" key={data.id}>
                                 <div className="col-span-2">
                                    <img
                                       alt="img"
                                       src={data.avatar_url}
                                       className=" h-7 w-7 rounded-full object-cover"
                                    />
                                 </div>
                                 <div className="col-span-7">
                                    <p className="font-bold text-[13px] text-white">{data.first_name} {data.last_name}</p>
                                    <p className="text-[12px] text-gray-600 mt-[-4px]">{data.username}</p>
                                 </div>
                                 <div className="col-span-3">
                                    <button className="bg-white text-black w-full text-[10px] py-1 rounded-full" onClick={() => handleFollow(data.id)}>Follow</button>
                                 </div>
                              </div>}
                           </>
                        ))}
                        <button
                           onClick={() => handleNavigate("/follow/suggestion")}
                           className="bg-white text-black w-full text-[12px] py-1 rounded-full"
                        >
                           View More
                        </button>
                     </div>
                  }
               </div>
               {/* who to follow end */}

               <hr className="w-full mt-3 h-[0.1px] bg-gray-800 border-0" />

               {/* footer section start */}
               <div className="mt-2">
                  <div className="text-nowrap">
                     <span className="pr-2 text-[12px] text-gray-600 cursor-pointer hover:underline" onClick={() => handleNavigate("/terms")}>Terms of Service</span>
                     <span className="pr-2 text-[12px] text-gray-600 cursor-pointer hover:underline" onClick={() => handleNavigate("/privacy")}>Privacy Policy</span><br />
                     <span className="pr-2 text-[12px] text-gray-600 cursor-pointer hover:underline" onClick={() => handleNavigate("/cookies")}>Cookie Policy</span>
                     <span className="pr-2 text-[12px] text-gray-600 cursor-pointer hover:underline" onClick={() => handleNavigate("/aboutus")}  >About us</span>
                     <span className="pr-2 text-[12px] text-gray-600 cursor-pointer hover:underline" onClick={() => handleNavigate("/contactus")}>Contact us</span>
                  </div>
                  <p className="mt-6 font-bold text-[10.5px] text-red-800">C / FE / SIDEMENU / V1 / MAR 23, 2024</p>
                  <p className="mt-1 font-bold text-[12px] text-white text-justify">Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc</p>
               </div>
               {/* footer section end */}

            </div>
         </div>
         {/* sidemenu end */}
      </Fragment >
   );
};

export default SideMenu;
