import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoSearch } from "react-icons/io5";
import { groupUserData } from "../data";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { startFollow } from "../redux/apislice/profileSlice";
import Skeleton from "react-loading-skeleton";

const WhoToFollow = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(groupUserData);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.totalUsers);
    const { loading, totalUser = [] } = userData


    useEffect(() => {
        if (Array.isArray(totalUser) && totalUser.length > 0) {
            const sortedData = [...totalUser].sort((a, b) => b.followers_count - a.followers_count);
            setFilteredData(sortedData);
        }
    }, [totalUser]);

    useEffect(() => {
        if (searchValue === '') {
            const sortedData = [...totalUser].sort((a, b) => b.followers_count - a.followers_count);
            setFilteredData(sortedData);
        } else if (searchValue.length >= 1) {
            const filtered = totalUser.filter((data) =>
                data.first_name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }, [searchValue, totalUser]);



    const handleFollow = (userId) => {
        const WhoToFollow = filteredData.map((data) => (data.id === userId ? { ...data, is_following: true } : data));
        setFilteredData(WhoToFollow);

        try {
            dispatch(startFollow(userId))?.then((result) => {
                if (result.error) {
                    toast.success(result.error)
                } else {
                    toast.success(result.payload.message)
                }
            });
        } catch (error) {
            console.error("error", error);
        }
    }


    return (
        <div className="mb-24">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / Who To Follow / V1 / Sep 11, 2024
                </p>
                <p className="text-black text-[9px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchValue}
                            placeholder="Search for people"
                            onChange={(e) => setSearchValue(e.target.value)}
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>

            {filteredData?.length > 0 ?
                <p className="px-2 text-[12px] font-semibold mt-1 mb-2">
                    Trending Accounts
                </p>
                : ""
            }
            {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                <div className="mb-2 border border-gray-300 p-1" key={index}>
                    <div className="grid grid-cols-12">
                        <div className="col-span-2 rounded-full">
                            <Skeleton height={35} width={35} style={{ borderRadius: "50%" }} />
                        </div>
                        <div className="col-span-10">
                            <div className="flex flex-col">
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-1">
                                        <Skeleton width={120} />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center" >
                                    <Skeleton width={180} />
                                    <Skeleton width={60} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ))}
            {filteredData?.length > 0 ? (
                filteredData?.map((data) => (
                    <div className="grid grid-cols-12 px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data?.first_name}
                                src={data?.avatar_url}
                                className="h-8 w-8 object-cover rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10">
                            <div className="flex justify-between items-start">
                                <div className="flex flex-col leading-[16px]">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[14px]">{data.first_name} {data.last_name}</p>
                                    </div>
                                    <p className="text-[#5c5c5c] text-[12px]">{data?.username}</p>
                                </div>
                                <button className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white" onClick={() => handleFollow(data?.id)}>
                                    {data?.is_following ? "unfollow" : "Follow"}
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center py-4">
                    <p className="text-gray-700 overflow-x-scroll">No results for "{searchValue}"</p>
                </div>
            )}
        </div>
    )
}

export default WhoToFollow


