import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoSearch } from "react-icons/io5";
import { groupPostData } from "../../data";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { CiBookmark } from "react-icons/ci";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

const GroupJoinedSearch = () => {
    const label = "C / FE / JOINED GROUP / SEARCH / V1 / JULY 22, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const handleSearch = () => {
        const filtered = groupPostData.filter(data =>
            data.userName.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.userTagname.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.postContent.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredData(filtered);
        setShowResults(true);
    };

    return (
        <div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchValue}
                            placeholder="Search Nature Water"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                setShowResults(false); // Clear results on input change
                            }}
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                onClick={handleSearch}
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>

            {showResults ? (
                filteredData.length > 0 ? (
                    filteredData.map((data) => (
                        <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                            <div className="col-span-2">
                                <img
                                    alt={data.userName}
                                    src={data.userProfileImage}
                                    className="h-8 w-8 rounded-full object-cover"
                                />
                            </div>
                            <div className="col-span-10 -ml-2">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                            <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                            <div className="flex items-center">
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                                <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data.postTime}</p>
                                            </div>
                                        </div>
                                        <div className="relative">
                                            <BsThreeDotsVertical
                                                className="text-[#5c5c5c] cursor-pointer absolute -right-2 -top-2"
                                            />
                                        </div>

                                    </div>
                                    <p className="text-black text-[12px]">{data.postContent}</p>
                                </div>
                                {data.postImage === null ? null :
                                    <img
                                        alt={data.postContent}
                                        src={data.postImage}
                                        className="w-full h-auto object-cover rounded-[6px]"
                                    />
                                }
                                <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FaRegComment className="text-[14px]" />
                                        <p className="text-[12px]">{data.postComments}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FaRegHeart className="text-[14px]" />
                                        <p className="text-[12px]">{data.postLikes}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <LuRepeat2 className="text-[14px]" />
                                        <p className="text-[12px]">{data.postRepost}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FiBarChart2 className="text-[14px]" />
                                        <p className="text-[12px]">{data.postImpression}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CiBookmark className="text-[14px] cursor-pointer" />
                                        <AiOutlineShareAlt className="text-[14px] cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-gray-700 py-4">No results found for "{searchValue}"</p>
                )
            ) : (
                <p className="text-center text-gray-700 py-4">Type to search for posts</p>
            )}
        </div>
    );
};

export default GroupJoinedSearch;
