import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart, FaHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { groupPostData } from "../../data";

const GroupPostDetails = ({ onShare }) => {
    const label = "C / FE / JOINED GROUP / POST DETAILS / V1 / JULY 27, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();
    const [likes, setLikes] = useState(false);
    const [likeCount, setLikeCount] = useState(578);
    const [bookmarks, setBookmarks] = useState(false);
    const [commentLikes, setCommentLikes] = useState(
        groupPostData.map((data) => ({
            id: data.id,
            likes: false,
            likeCount: data.likeCount || 0,
        }))
    );
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [repliesVisible, setRepliesVisible] = useState({});

    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleLikeClick = () => {
        setLikeCount(likes ? likeCount - 1 : likeCount + 1);
        setLikes(!likes);
    };

    const handleCommentLikeClick = (commentId) => {
        setCommentLikes((prevCommentLikes) =>
            prevCommentLikes.map((comment) =>
                comment.id === commentId
                    ? {
                        ...comment,
                        likes: !comment.likes,
                        likeCount: comment.likes ? comment.likeCount - 1 : comment.likeCount + 1,
                    }
                    : comment
            )
        );
    };

    const toggleMenu = (commentId) => {
        setActiveMenuId((prevId) => (prevId === commentId ? null : commentId));
    };

    const toggleRepliesVisibility = (id) => {
        setRepliesVisible((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <div className="pb-28">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="bg-white sticky top-0 z-10">
                <div className="px-2 flex items-center justify-start gap-4 pt-1">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-black text-[25px] cursor-pointer"
                    />
                    <div className="flex flex-col leading-4">
                        <p className="font-bold text-[12px]">Group Post</p>
                        <p className="text-[12px] text-[#898989]">Nature Water</p>
                    </div>
                </div>
                <div className="h-[0.4px] bg-[#b2b2b2] w-full my-[3px]" />
            </div>
            <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]">
                {/* User name, tagname */}
                <div className="flex flex-col">
                    <div className="flex items-start justify-between relative">
                        <div className="flex items-start gap-2">
                            <img
                                alt="user"
                                className="h-8 w-8 rounded-full object-cover"
                                src="https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                            <div className="flex flex-col items-center leading-4">
                                <p className="text-black text-[12px] font-bold">John Smith</p>
                                <p className="text-[#5c5c5c] text-[12px] font-thin">@johnsmith</p>
                            </div>
                        </div>
                        <button className="text-white bg-black px-3 py-[3px] text-[12px] rounded-full mr-5">
                            Follow
                        </button>
                        <BsThreeDotsVertical
                            onClick={() => toggleMenu(0)} // 0 to represent post menu
                            className="text-[#5c5c5c] cursor-pointer absolute -right-[6px]"
                        />
                        {activeMenuId === 0 && (
                            <div className="absolute top-0 right-2 bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]">comment</p>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                <p className="px-3 cursor-pointer">Block</p>
                            </div>
                        )}
                    </div>
                    <p className="text-black text-[12px] my-1">
                        Loving the beach vibes!
                    </p>
                </div>
                {/* Post image */}
                <img
                    alt="post"
                    src="https://cdn.pixabay.com/photo/2013/02/21/19/06/drink-84533_1280.jpg"
                    className="w-full h-auto object-cover rounded-[6px]"
                />
                {/* Like, Comment, Share, Bookmark */}
                <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                    <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate("/group/joined/comment")}>
                        <FaRegComment className="text-[14px]" />
                        <p className="text-[12px]">20</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1" onClick={handleLikeClick}>
                        {likes ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                        <p className="text-[12px]">{likeCount}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <LuRepeat2 className="text-[14px]" />
                        <p className="text-[12px]">12</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <FiBarChart2 className="text-[14px]" />
                        <p className="text-[12px]">5059</p>
                    </div>
                    <div className="flex items-center gap-2">
                        {bookmarks ?
                            <FaBookmark
                                className="text-[12px] text-blue-500 cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            /> :
                            <FaRegBookmark
                                className="text-[12px] cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            />}
                        <AiOutlineShareAlt
                            className="text-[14px] cursor-pointer"
                            onClick={() => onShare("https://www.post.share.com")}
                        />
                    </div>
                </div>
            </div>
            {/* Post time */}
            <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]">
                <div className="flex items-center gap-1 text-[12px] text-[#4d4d4d]">
                    <p>7:56 AM</p>
                    <p>·</p>
                    <p>21 Jul 24</p>
                    <p>·</p>
                    <p className="text-[#000000] font-bold">13.2K Views</p>
                </div>
            </div>
            {groupPostData.map((data) => {
                const commentLike = commentLikes.find(comment => comment.id === data.id);

                return (
                    <div className="py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="px-2 flex items-start justify-between relative">
                            <div className="flex items-start gap-2">
                                <img
                                    alt="user"
                                    className="h-7 w-7 rounded-full object-cover"
                                    src={data.userProfileImage}
                                />
                                <div className="flex flex-col leading-[14px]">
                                    <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                </div>
                            </div>
                            <BsThreeDotsVertical
                                onClick={() => toggleMenu(data.id)}
                                className="text-[#5c5c5c] cursor-pointer absolute right-0"
                            />
                            {activeMenuId === data.id && (
                                <div className="absolute top-[-3px] right-[7px] bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Reply</p>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                    <p className="px-3 py-[1px] cursor-pointer">Block</p>
                                </div>
                            )}
                        </div>
                        <div className="pt-1">
                            <p className="text-black text-[12px] px-2">{data.postContent}</p>
                            <div className="flex items-center justify-between pt-[5px] px-2">
                                <p
                                    className="text-[10px] font-bold text-light-blue-500 cursor-pointer"
                                    onClick={() => toggleRepliesVisibility(data.id)}
                                >
                                    {repliesVisible[data.id] ? "Hide replies" : "View replies"}
                                </p>
                                <div
                                    className="flex items-center cursor-pointer gap-1 text-[#4d4d4d]"
                                    onClick={() => handleCommentLikeClick(data.id)}
                                >
                                    {commentLike.likes ? <FaHeart className="text-[12px] text-red-500" /> : <FaRegHeart className="text-[12px]" />}
                                    <p className="text-[10px]">{commentLike.likeCount}</p>
                                </div>
                            </div>
                            {repliesVisible[data.id] && (
                                <div className="mt-2 pl-4 bg-[#d8d8d8]">
                                    <div className="px-2 pt-1 flex items-start justify-between relative">
                                        <div className="flex items-start gap-2">
                                            <img
                                                alt="user"
                                                className="h-7 w-7 rounded-full object-cover"
                                                src={data.userProfileImage}
                                            />
                                            <div className="flex flex-col leading-[14px]">
                                                <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                            </div>
                                        </div>
                                        <BsThreeDotsVertical
                                            className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                        />
                                    </div>
                                    <div className="py-1 px-2">
                                        <p className="text-black text-[12px]">{data.postContent}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GroupPostDetails;
