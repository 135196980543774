import React, { Fragment } from "react";
import SideMenu from "../SideMenu";
import { IoSearch } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Avatar } from "@material-tailwind/react";
import { MdOutlineGroupAdd } from "react-icons/md";
import { joinedGroups, suggestedGroups } from "../../data";
import { useNavigate } from "react-router-dom";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};

const GroupMain = () => {
   const lable = "C / FE / GROUPMAIN PAGE / V2 / MAY 13, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
               <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="flex items-center justify-between gap-2 px-2 mt-1">
               <div className="flex items-center gap-4">
                  <SideMenu />
                  <p>Groups</p>
               </div>
               <div className="flex items-center gap-3">
                  <IoSearch className="text-[19px] cursor-pointer" onClick={() => navigate("/group/search")} />
                  <MdOutlineGroupAdd className="text-[23px] cursor-pointer" onClick={() => navigate("/group/create")} />
               </div>
            </div>

            <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />

            <div className="mt-1">
               <p className="px-1 text-[12px] font-bold">Joined Groups</p>
               <Carousel
                  arrows={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                  className="pt-1"
               >
                  {joinedGroups.map((data) => (
                     <div className="px-[4px] select-none relative" key={data.id} onClick={() => navigate("/group/joined/home")}>
                        <img
                           alt={data.name}
                           src={data.imgUrl}
                           className="w-full h-[3rem] rounded-tl-xl rounded-tr-xl object-cover cursor-pointer"
                        />
                        <div className="bg-black">
                           <p className="text-[10px] text-white p-1 line-clamp-1">{data.name}</p>
                        </div>
                        {data.notifications &&
                           <div className="absolute top-0 right-0 h-3 w-3 rounded-full bg-red-500 border-[2px] border-[#e3e3e3]"></div>
                        }
                     </div>
                  ))}
               </Carousel>

               <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />
            </div>
         </div>

         <div className="mt-2 mb-5">
            <p className="px-2 mb-1 text-[12px] font-bold">Suggested Groups</p>
            {suggestedGroups.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id}>
                  <img
                     alt={data.name}
                     src={data.imgUrl}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col justify-between">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-3">
                           <p className="text-[12px] line-clamp-1">{data.name}</p>
                           <p className="text-[10px] text-gray-500">{data.members} Members</p>
                        </div>
                        <button className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black w-[50px] mt-1">
                           Join
                        </button>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {data.membersDeatils.map((member) => (
                           <Avatar
                              key={member.id}
                              variant="circular"
                              alt={member.name}
                              className="border-2 border-white h-8 w-8 cursor-pointer hover:z-10 focus:z-10"
                              src={member.profileImage}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </Fragment>
   )
}

export default GroupMain