import React from "react";
import { FaUserGroup } from "react-icons/fa6";
import { FaGlobeAmericas } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { MdNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const AccountOwnerGroupAbout = () => {
    const navigate = useNavigate();

    return (
        <div className="px-0">
            <div className="px-2 text-black border-b-[0.4px] border-[#b2b2b2] pb-2">
                <p className="text-[14px] font-semibold">Group Info</p>
                <p className="text-[12px] font-extralight my-2">Conversations about nature water</p>
                <div className="flex items-start gap-2 mt-1">
                    <FaUserGroup />
                    <p className="text-[12px] font-extralight">Only Group members can post, like, or reply.</p>
                </div>
                <div className="flex items-start gap-2 mt-1">
                    <FaGlobeAmericas />
                    <p className="text-[12px] font-extralight">All Groups are publicly visible. Anyone can join this Group.</p>
                </div>
                <div className="flex items-start gap-2 mt-1">
                    <IoCalendarOutline />
                    <p className="text-[12px] font-extralight">Created 16 June 2024 by <span className="text-blue-500 font-bold cursor-pointer">@johnsmith</span></p>
                </div>
            </div>

            <div className="px-2 text-black border-b-[0.4px] border-[#b2b2b2] pb-2">
                <p className="text-[14px] font-semibold mt-2">Rules</p>
                <p className="text-[12px] font-extralight my-2">Group rules are enforced by Group leaders, and are in addition to our Rules.</p>
                <div className="flex items-start gap-2 mt-1">
                    <p className="h-6 w-6 rounded-full p-2 text-[12px] font-extralight flex items-center justify-center bg-[#140f20] text-white">1</p>
                    <p className="text-[13px] font-bold mt-[2px]">Be kind and respectfull.</p>
                </div>
                <div className="flex items-start gap-2 mt-1">
                    <p className="h-6 w-6 rounded-full p-2 text-[12px] font-extralight flex items-center justify-center bg-[#140f20] text-white">2</p>
                    <p className="text-[13px] font-bold mt-[2px]">Keep post on topic.</p>
                </div>
                <div className="flex items-start gap-2 mt-1">
                    <p className="h-6 w-6 rounded-full p-2 text-[12px] font-extralight flex items-center justify-center bg-[#140f20] text-white">3</p>
                    <p className="text-[13px] font-bold mt-[2px]">Explore and share.</p>
                </div>
            </div>

            <div className="px-2 text-black border-b-[0.4px] border-[#b2b2b2] pb-2">
                <p className="text-[14px] font-semibold mt-2">Moderators</p>
                <div className="flex items-center justify-between cursor-pointer mt-1" onClick={() => navigate("/account/owner/group/moderators")}>
                    <p className="text-[12px] font-extralight">2 Moderators</p>
                    <MdNavigateNext className="text-[20px]" />
                </div>
            </div>

            <div className="px-2 text-black border-b-[0.4px] border-[#b2b2b2] pb-2">
                <p className="text-[14px] font-semibold mt-2">Members</p>
                <div className="flex items-center justify-between cursor-pointer mt-1" onClick={() => navigate("/account/owner/group/members")}>
                    <p className="text-[12px] font-extralight">34507 Members</p>
                    <MdNavigateNext className="text-[20px]" />
                </div>
            </div>
        </div>
    )
}

export default AccountOwnerGroupAbout