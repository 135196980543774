import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../SideMenu";
import { MdOutlineGroupAdd } from "react-icons/md";
import { Avatar } from "@material-tailwind/react";
import { suggestedGroups } from "../../../data";

const GroupYourList = () => {
   const lable = "C / FE / ACCOUNT OWNER GROUP LIST / V1 / JULY 16, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
               <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <div className="flex items-center justify-between gap-2 px-2 mt-1">
               <div className="flex items-center gap-4">
                  <SideMenu />
                  <p>Your Groups</p>
               </div>
               <MdOutlineGroupAdd className="text-[23px] cursor-pointer" onClick={() => navigate("/account/owner/group/create")} />
            </div>

            <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />
         </div>

         <div className="overflow-y-scroll">
            {suggestedGroups.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id}>
                  <img
                     alt={data.name}
                     src={data.imgUrl}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col justify-between">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-[16px]">
                           <p className="text-[14px] line-clamp-1">{data.name}</p>
                           <p className="text-[12px] text-gray-500">{data.members} Members</p>
                        </div>
                        <button className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black hover:opacity-90 w-[50px] mt-1" onClick={() => navigate("/account/owner/group")}>
                           View
                        </button>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {data.membersDeatils.map((member) => (
                           <Avatar
                              key={member.id}
                              variant="circular"
                              alt={member.name}
                              className="border-2 border-white h-7 w-7 cursor-pointer hover:z-10 focus:z-10"
                              src={member.profileImage}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </Fragment>
   )
}

export default GroupYourList