import React, { useState } from "react";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const MessageSettings = () => {
   const lable = "C / FE / MESSAGE SETTING / V1 / MAY 10, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
   const navigate = useNavigate();

   const [selectedOption, setSelectedOption] = useState("");

   // Function to handle radio button selection
   const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
   };

   return (
      <div>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <div className="flex items-center justify-start gap-5 px-2 pt-1">
            <IoCaretBackCircle
               onClick={() => navigate(-1)}
               className="text-[23px] cursor-pointer"
            />
            <div className="flex flex-col leading-4">
               <p className="text-[14px]">Messages Settings</p>
               <p className="text-[11px] text-[#696969]">@johnsmith</p>
            </div>
         </div>
         
         <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />

         <div className="mt-2 px-2">
            <p className="text-[14px] font-bold">Allow message requests from</p>
            <p className="text-[10px]">People you follow will always be able to message you.</p>

            <div className="flex flex-col gap-3 mt-3">
               <div className="flex items-center justify-between">
                  <p className="text-[12px]">No one</p>
                  <input
                     type="radio"
                     name="messageOption"
                     value="noOne"
                     checked={selectedOption === "noOne"}
                     onChange={handleOptionChange}
                     className="h-4 w-4 cursor-pointer accent-[#1E7BAE]"
                  />
               </div>
               <div className="flex items-center justify-between">
                  <p className="text-[12px]">People you follow</p>
                  <input
                     type="radio"
                     name="messageOption"
                     value="peopleYouFollow"
                     checked={selectedOption === "peopleYouFollow"}
                     onChange={handleOptionChange}
                     className="h-4 w-4 cursor-pointer accent-[#1E7BAE]"
                  />
               </div>
               <div className="flex items-center justify-between">
                  <p className="text-[12px]">Everyone</p>
                  <input
                     type="radio"
                     name="messageOption"
                     value="everyone"
                     checked={selectedOption === "everyone"}
                     onChange={handleOptionChange}
                     className="h-4 w-4 cursor-pointer accent-[#1E7BAE]"
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default MessageSettings;
