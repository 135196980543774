import React from "react";
import HashtagMain from "../components/hashtag/HashtagMain";

export default function HashtagPage() {
    return (
        <>
            <HashtagMain />
        </>
    )
}
