import React, { useState, useRef, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Textarea } from "@material-tailwind/react";
import { IoCaretBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { createPost, getPostDetails, updatePost } from '../redux/apislice/postSlice';
import toast, { Toaster } from 'react-hot-toast';
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoImage, IoVideocam, IoCloseCircle } from "react-icons/io5";
import { fetchProfile } from "../redux/apislice/profileSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PostPage = () => {
   const lable = "C / FE / POST PAGE / V2 / MAY 21, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
   const params = useParams();
   const { postId } = params;

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const imageInputRef = useRef();
   const videoInputRef = useRef();

   const [selectedImage, setSelectedImage] = useState(null);
   const [selectedVideo, setSelectedVideo] = useState(null);
   const [selectedFile, setSelectedFile] = useState(null);
   const [text, setText] = useState("");
   const [userDetails, setUserDetails] = useState(null);
   const { loading, postDetail } = useSelector((state) => state.post);
   const profile = useSelector(state => state.profile);

   const handleImageChange = (e) => {
      setSelectedFile(e.target.files[0]);
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
      setSelectedVideo(null);
      e.target.value = null;
   };

   const handleVideoChange = (e) => {
      setSelectedFile(e.target.files[0]);
      setSelectedVideo(URL.createObjectURL(e.target.files[0]));
      setSelectedImage(null);
      e.target.value = null;
   };

   const handleClear = () => {
      setSelectedImage(null);
      setSelectedVideo(null);
      setSelectedFile(null);
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append('body', text);
      if (selectedFile) {
         formData.append('file', selectedFile);
      }

      if (selectedImage && selectedFile) {
         formData.append('image', selectedFile);
      } else if (selectedVideo && selectedFile) {
         formData.append('video', selectedFile);
      }

      if (postId) {
         dispatch(updatePost({ postId, formData })).then(() => {
            toast.success('Post Updated successfully!');
            setTimeout(() => navigate("/account"), 1500);
            handleClear()
         });

      } else {
         dispatch(createPost(formData))
            .then(() => {
               toast.success('Post created successfully!');
               setTimeout(() => navigate("/account"), 1500);
            });
      }
   };


   useEffect(() => {
      setUserDetails(profile.user);
   }, [profile]);

   useEffect(() => {
      dispatch(fetchProfile());
   }, [dispatch]);

   useEffect(() => {
      if (postId) {
         dispatch(getPostDetails(postId));
      }
   }, [dispatch, postId]);

   useEffect(() => {
      if (postId) {
         setText(postDetail?.body);
         setSelectedImage(postDetail?.image);
         setSelectedVideo(postDetail?.video);
      }
      return (() => handleClear())
   }, [postId, postDetail?.body, postDetail?.image, postDetail?.video])
   
   return (
      (<Fragment>
         <Toaster
            position="top-right"
            reverseOrder={false}
         />
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <form className="px-2 mt-2 mb-[7rem]" onSubmit={handleSubmit}>
            <div className="flex items-center justify-between">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="text-[30px] md:text-[23px] cursor-pointer"
               />

               {loading ?
                  <ScaleLoader
                     color={"#000"}
                     loading={loading}
                     height={10}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  :
                  <button type="submit" className="text-[15px] md:text-[12px] bg-[#000000] hover:bg-[#1a1a1a] text-white px-3 py-1 rounded-full">
                     {postId ? "Save" : "Post"}
                  </button>
               }
            </div>
            <div className="flex items-center justify-between mt-6 mb-3">
               <div className="flex items-center justify-start gap-2">
                  {profile.loading ? <Skeleton circle width={40} height={40} /> :
                     <img
                        alt="profile"
                        src={userDetails?.avatar_url}
                        className="h-11 w-11 md:h-8 md:w-8 rounded-full object-cover"
                     />
                  }
                  {/* <button className="border border-[#000000] text-[#000000] text-[18px] md:text-[13px] px-3 py-[3px] rounded-full">
                     Everyone
                  </button> */}
                  <select style={{ appearance: "none" }} className="border w-28 border-[#000000] text-[#000000]  text-[18px] md:text-[13px] px-3 py-[3px] rounded-full">
                     <option value="Everyone">Everyone</option>
                     <option value="Everyone">Only Followers</option>
                     <option value="Everyone">Only Following</option>
                  </select>
               </div>
               <div className="flex items-center gap-1">
                  <div className="bg-[#000000] hover:bg-[#1a1a1a] text-white p-[7px] md:p-[5px] cursor-pointer rounded-full" onClick={() => imageInputRef.current.click()}>
                     <IoImage className="text-[20px] md:text-[15px]" />
                  </div>
                  <div className="bg-[#000000] hover:bg-[#1a1a1a] text-white p-[7px] md:p-[5px] cursor-pointer rounded-full" onClick={() => videoInputRef.current.click()}>
                     <IoVideocam className="text-[20px] md:text-[15px]" />
                  </div>
               </div>
            </div>
            <Textarea required variant="outlined" label="What's happening?" value={text} onChange={(e) => setText(e.target.value)} />
            <input type="file" ref={imageInputRef} style={{ display: 'none' }} onChange={handleImageChange} accept='image/*' />
            <input type="file" ref={videoInputRef} style={{ display: 'none' }} onChange={handleVideoChange} accept='video/*' />
            {(selectedImage || selectedVideo) && (
               <div className="flex flex-col items-end">
                  <IoCloseCircle
                     onClick={handleClear}
                     className="text-black hover:text-[#1a1a1a] text-[28px] md:text-[22px] cursor-pointer mb-1"
                  />
                  {selectedImage ?
                     <img
                        alt="Selected"
                        src={selectedImage}
                        className="w-[65%] md:w-1/2 h-fit rounded-md"
                     />
                     :
                     <video
                        autoPlay
                        muted
                        loop
                        src={selectedVideo}
                        className="w-[65%] md:w-1/2 h-fit rounded-md"
                     />
                  }
               </div>
            )}
         </form>
      </Fragment >)
   );
}

export default PostPage;